import React, { MouseEvent, useState, useEffect } from "react";
import {
  Tooltip,
  Grid,
  IconButton,
  Backdrop,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import { useAuth } from "react-oidc-context";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
//import DownloadReportIcon from '@mui/icons-material/DownloadOutlined';
import DownloadReportIcon from "@mui/icons-material/SummarizeOutlined";
import DownloadInvoicesIcon from "@mui/icons-material/DownloadOutlined";

import {
  EtlInvoiceJobsWhereQuery,
  useEtlInvoiceJobFullQuery,
} from "../../generated/graphql";
import ETLInvoiceJobItemsTable from "./ETLInvoiceJobItemsTable";
import PaginationButtons from "../TablePagination";
import { handleReportDownload } from "../lib/Tools";
import dayjs from "dayjs";
import theme from "../../Theme";

interface Props {
  etlinvoicejobData: EtlInvoiceJobsWhereQuery;
  handlePrevPage: () => void;
  handleNextPage: () => void;
  currentPage: number;
  totalPages: number;
}

// This is a wrapper to load a table with etlinvoicejob items
const ETLInvoiceJobItemsTableWrapper: React.FC<{ id: number }> = ({
  id: id,
}) => {
  const { data, loading, error, refetch } = useEtlInvoiceJobFullQuery({
    variables: {
      where: { id: id },
    },
  });

  useEffect(() => {
    refetch();
  }, [id]);

  if (loading) return <></>;
  if (error) return <p>Error :</p>;

  return data && data.eTLInvoiceJob ? (
    <ETLInvoiceJobItemsTable etlInvoiceJob={data} />
  ) : null;
};

const ETLInvoiceJobs: React.FC<Props> = ({
  etlinvoicejobData,
  handlePrevPage,
  handleNextPage,
  currentPage,
  totalPages,
}) => {
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<{
    [key: number]: null | HTMLElement;
  }>({});
  const open = Boolean(anchorEl);

  // List of etlinvoicejobs selected by the user
  const [selectedETLInvoiceJobs, setSelectedETLInvoiceJobs] = useState<
    number[]
  >([]);

  // ETLInvoiceJob currently opened
  const [openETLInvoiceJobId, setOpenETLInvoiceJobId] = useState<null | number>(
    null,
  );

  const handleActionsMenuClick = (
    event: MouseEvent<HTMLElement>,
    jobId: number,
  ) => {
    setAnchorEl({ [jobId]: event.currentTarget });
  };

  // Modify handleClose to accept an invoice number
  const handleClose = (jobId: number) => {
    setAnchorEl((prevState) => ({ ...prevState, [jobId]: null }));
  };

  const handleCheckboxChange = (etlinvoicejobNumber: number) => {
    if (selectedETLInvoiceJobs.includes(etlinvoicejobNumber)) {
      setSelectedETLInvoiceJobs(
        selectedETLInvoiceJobs.filter(
          (number) => number !== etlinvoicejobNumber,
        ),
      );
    } else {
      setSelectedETLInvoiceJobs([
        ...selectedETLInvoiceJobs,
        etlinvoicejobNumber,
      ]);
    }
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ display: "flex", alignItems: "center", mt: 2 }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {/*<TableCell sx={{ color: theme.palette.primary.main }} >Select</TableCell>*/}
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
              >
                Date
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
              >
                ID
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
              >
                Data
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
              >
                Period
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
              >
                Invoices
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
              >
                Status
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
              >
                Errors
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
                colSpan={2}
              >
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  alignItems="center"
                >
                  <Grid item xs={6}>
                    <Typography variant="h4" component="h4" align="right">
                      {currentPage} / {totalPages}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <PaginationButtons
                      onPrev={handlePrevPage}
                      onNext={handleNextPage}
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {etlinvoicejobData.eTLInvoiceJobs?.map((etlJob, _) => (
              <React.Fragment key={etlJob.id}>
                <TableRow>
                  <TableCell align="center">
                    {dayjs(etlJob.createdAt).format("MM/DD/YYYY")}
                  </TableCell>
                  <TableCell align="center">{etlJob.id}</TableCell>
                  <TableCell align="left">{etlJob.dataId}</TableCell>
                  <TableCell align="center">
                    {etlJob.from
                      ? dayjs(etlJob.from).format("MM/DD/YYYY")
                      : "N/A"}{" "}
                    -{" "}
                    {etlJob.to ? dayjs(etlJob.to).format("MM/DD/YYYY") : "N/A"}
                  </TableCell>
                  <TableCell align="center">
                    {etlJob._count?.invoices}
                  </TableCell>
                  <TableCell align="center">{etlJob.status}</TableCell>
                  <TableCell align="right">{etlJob.errors}</TableCell>
                  <TableCell align="right">
                    <Tooltip title="Report PDF">
                      <IconButton
                        onClick={async () => {
                          try {
                            setLoading(true);
                            await handleReportDownload(
                              auth.user?.access_token,
                              "Report",
                              etlJob.dataId,
                              etlJob.id,
                            );
                            handleClose(etlJob.id);
                          } finally {
                            setLoading(false);
                          }
                        }}
                      >
                        <DownloadReportIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Invoices ZIP">
                      <IconButton
                        onClick={async () => {
                          try {
                            setLoading(true);
                            await handleReportDownload(
                              auth.user?.access_token,
                              "ReportInvoices",
                              etlJob.dataId,
                              etlJob.id,
                            );
                            handleClose(etlJob.id);
                          } finally {
                            setLoading(false);
                          }
                        }}
                      >
                        <DownloadInvoicesIcon />
                      </IconButton>
                    </Tooltip>
                    <IconButton
                      onClick={() => {
                        if (openETLInvoiceJobId === etlJob.id) {
                          setOpenETLInvoiceJobId(null); // If the etlinvoicejob is currently open, close it
                        } else {
                          setOpenETLInvoiceJobId(etlJob.id); // Otherwise, open this etlinvoicejob
                        }
                      }}
                    >
                      {openETLInvoiceJobId === etlJob.id ? (
                        <ArrowDropUpIcon />
                      ) : (
                        <ArrowDropDownIcon />
                      )}
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow
                  style={{
                    display:
                      openETLInvoiceJobId === etlJob.id ? "table-row" : "none",
                  }}
                >
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={10}
                  >
                    {openETLInvoiceJobId === etlJob.id && (
                      <ETLInvoiceJobItemsTableWrapper id={etlJob.id} />
                    )}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <CircularProgress />
      </Backdrop>
    </>
  );
};

export default ETLInvoiceJobs;
