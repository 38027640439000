import React, { ErrorInfo, ReactNode } from 'react';

// First, define the type of the error that we'll use in this boundary
interface CustomError extends Error {
  react?: boolean;  // add custom properties if needed
}

// Then, define the state for this component
interface State {
  hasError: boolean;
  error?: CustomError;
  errorInfo?: ErrorInfo;
}

// And the props. Here we just have children, which are the components
// this boundary will wrap around. Its type is ReactNode, which is any
// valid thing that can be rendered in JSX.
interface Props {
  children: ReactNode;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: CustomError) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error };
  }

  componentDidCatch(error: CustomError, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.error('Uncaught error:', error, errorInfo);
    this.setState({ errorInfo: errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h1>Something went wrong.</h1>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo?.componentStack}
          </details>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
