import React, { MouseEvent, useState } from "react";
import {
  Tooltip,
  Backdrop,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import DownloadCSVIcon from "@mui/icons-material/TextSnippetOutlined";
import { useAuth } from "react-oidc-context";
import PaginationButtons from "../TablePagination";
import { humanFileSize, humanDateTime, handleCSVDownload } from "../lib/Tools";
import theme from "../../Theme";
import { EtlFilesQuery } from "../../generated/graphql";

interface Props {
  etlFilesData: EtlFilesQuery;
  handlePrevPage: () => void;
  handleNextPage: () => void;
  currentPage: number;
  totalPages: number;
}

const ETLFiles: React.FC<Props> = ({
  etlFilesData,
  handlePrevPage,
  handleNextPage,
  currentPage,
  totalPages,
}) => {
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<{
    [key: string]: null | HTMLElement;
  }>({});
  const open = Boolean(anchorEl);

  // Modify handleClose to accept an invoice number
  const handleClose = (hash: string) => {
    setAnchorEl((prevState) => ({ ...prevState, [hash]: null }));
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ display: "flex", alignItems: "center", mt: 2 }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {/*<TableCell sx={{ color: theme.palette.primary.main }} >Select</TableCell>*/}
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
              >
                Updated
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
              >
                Size
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
                colSpan={10}
              >
                Name
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
              ></TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
              >
                Hash
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
              >
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  alignItems="center"
                >
                  <Grid item xs={6}>
                    <Typography variant="h4" component="h4" align="right">
                      {currentPage} / {totalPages}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <PaginationButtons
                      onPrev={handlePrevPage}
                      onNext={handleNextPage}
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {etlFilesData.etlFiles
              ?.filter((etlFile) => etlFile.name.endsWith(".csv"))
              .map((etlFile, _) => (
                <React.Fragment key={etlFile.hash}>
                  <TableRow>
                    <TableCell align="center">
                      {etlFile.mtime
                        ? humanDateTime(new Date(etlFile.mtime))
                        : "-"}
                    </TableCell>
                    <TableCell align="center">
                      {humanFileSize(etlFile.size)}
                    </TableCell>
                    <TableCell align="left" colSpan={11}>
                      {etlFile.name}
                    </TableCell>
                    <TableCell align="center">{etlFile.hash}</TableCell>
                    <TableCell align="center">
                      <Tooltip title="Download CSV">
                        <IconButton
                          onClick={async () => {
                            try {
                              setLoading(true);
                              await handleCSVDownload(
                                auth.user?.access_token,
                                etlFile.name,
                              );
                              handleClose(etlFile.hash);
                            } finally {
                              setLoading(false);
                            }
                          }}
                        >
                          <DownloadCSVIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <CircularProgress />
      </Backdrop>
    </>
  );
};

export default ETLFiles;
