import React from "react";
import {
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import EditUserIcon from "@mui/icons-material/ModeEditOutline";
import DelteUserIcon from "@mui/icons-material/PersonRemoveOutlined";
import PaginationButtons from "../../TablePagination";
import { humanDateTime } from "../../lib/Tools";
import theme from "../../../Theme";
import { IUsersWhereQuery } from "../../../generated/graphql";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "../../lib/ConfirmationDialog";
import { useState } from "react";
import is from "date-fns/locale/is";
interface Props {
  IUsersData: IUsersWhereQuery;
  handlePrevPage: () => void;
  handleNextPage: () => void;
  deleteUser: (id: number) => Promise<void>;
  currentPage: number;
  totalPages: number;
}

const IUsersTable: React.FC<Props> = ({
  IUsersData,
  handlePrevPage,
  handleNextPage,
  deleteUser,
  currentPage,
  totalPages,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [isDeleteUserDialog, setDeleteUserDialog] = useState(false);
  const [userToDelete, setUserToDelete] = useState<null | {
    id: number;
    email: string;
  }>(null);

  const handleUserDelete = async (id: number) => {
    try {
      await deleteUser(id);
      // Close the dialog
      setDeleteUserDialog(false);
      // Optionally, do something here with the result, e.g., refetching data
    } catch (e) {
      // Handle any errors, e.g., by displaying a message to the user
      console.error(e);
    }
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ display: "flex", alignItems: "center", mt: 2 }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {/*<TableCell sx={{ color: theme.palette.primary.main }} >Select</TableCell>*/}
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
              >
                Email
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
              >
                Name
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
              >
                Last Name
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
              >
                DK #
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
              >
                Updated
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
              >
                Status
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
              >
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  alignItems="center"
                >
                  <Grid item xs={6}>
                    <Typography variant="h4" component="h4" align="right">
                      {currentPage} / {totalPages}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <PaginationButtons
                      onPrev={handlePrevPage}
                      onNext={handleNextPage}
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {IUsersData.iUsers?.map((iUser, _) => (
              <React.Fragment key={iUser.id}>
                <TableRow>
                  <TableCell align="left">{iUser.email}</TableCell>
                  <TableCell align="left">{iUser.name}</TableCell>
                  <TableCell align="left">{iUser.lastName}</TableCell>
                  <TableCell align="left">
                    {iUser.customers.map((c) => c.dkNumber).join(",")}
                  </TableCell>
                  <TableCell align="center">
                    {iUser.updatedAt
                      ? humanDateTime(new Date(iUser.updatedAt))
                      : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {iUser.enabled ? "ENABLED" : "DISABLED"}
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Edit user ...">
                      <IconButton
                        onClick={() => {
                          navigate("/user/" + iUser.id);
                        }}
                      >
                        <EditUserIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete user ...">
                      <IconButton
                        onClick={() => {
                          setUserToDelete({ id: iUser.id, email: iUser.email });
                          setDeleteUserDialog(true);
                        }}
                      >
                        <DelteUserIcon />
                      </IconButton>
                    </Tooltip>

                    <Dialog
                      open={isDeleteUserDialog}
                      onClose={() => {
                        setUserToDelete(null);
                        setDeleteUserDialog(false);
                      }}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        "CONFIRM DELETE"
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          {"Are you sure you want to delete the user : "}
                          <strong>
                            {(userToDelete?.email || "") +
                              " (" +
                              (userToDelete?.id || "") +
                              ")"}
                          </strong>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          variant="contained"
                          onClick={() => {
                            setDeleteUserDialog(false);
                          }}
                          sx={{ bgcolor: "#C0B7A4", color: "white" }}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => {
                            if (userToDelete !== null)
                              handleUserDelete(userToDelete.id);
                          }}
                          autoFocus
                          color="error"
                        >
                          Confirm
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <CircularProgress />
      </Backdrop>
    </>
  );
};

export default IUsersTable;
