import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import PeopleIcon from "@mui/icons-material/Person2";
import UsersIcon from "@mui/icons-material/PeopleOutline";
import CustomersIcon from "@mui/icons-material/BusinessOutlined";
import TppInvoiceSearchIcon from "@mui/icons-material/HotelOutlined";
import EltInvoiceSearchIcon from "@mui/icons-material/FlightTakeoffOutlined";
import ETLFilesSearchIcon from "@mui/icons-material/TextSnippetOutlined";
import ETLJobsSearchIcon from "@mui/icons-material/ManageHistoryOutlined";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import ReportIcon from "@mui/icons-material/ListAltOutlined";

import { useAuthz, PERMISSIONS } from "../../hooks/Authorization";
import { useNavigate } from "react-router-dom";
import ListSubheader from "@mui/material/ListSubheader";

interface AuthzItemProps {
  permissions: string[];
  to: string;
  label: string;
  icon: React.ReactNode;
}

function AuthzItem({ permissions, to, label, icon }: AuthzItemProps) {
  const isAllowed = useAuthz();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(to);
  };

  if (isAllowed(permissions))
    return (
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={label} />
      </ListItemButton>
    );
  return null;
}

export const inoviceMenuItems = (
  <React.Fragment>
    <AuthzItem
      permissions={[
        PERMISSIONS.VIEW_TPP_INVOICES,
        PERMISSIONS.VIEW_TPP_OWN_INVOICES,
      ]}
      to={"/tpp/invoices"}
      label={"TPP Invoices"}
      icon={<TppInvoiceSearchIcon />}
    />
    <AuthzItem
      permissions={[
        PERMISSIONS.VIEW_ELT_INVOICES,
        PERMISSIONS.VIEW_ELT_OWN_INVOICES,
      ]}
      to={"/elt/invoices"}
      label={"ELT Invoices"}
      icon={<EltInvoiceSearchIcon />}
    />
    <AuthzItem
      permissions={[PERMISSIONS.VIEW_JOBS]}
      to={"/etl-jobs"}
      label={"Import Jobs"}
      icon={<ETLJobsSearchIcon />}
    />
    <AuthzItem
      permissions={[PERMISSIONS.VIEW_JOBS]}
      to={"/etl-files"}
      label={"Import Files"}
      icon={<ETLFilesSearchIcon />}
    />
  </React.Fragment>
);

export const reportMenuItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Saved reports
    </ListSubheader>

    <ListItemButton>
      <ListItemIcon>
        <ReportIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <ReportIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <ReportIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end" />
    </ListItemButton>
  </React.Fragment>
);

export const customersMenuItems = (
  <React.Fragment>
    <AuthzItem
      permissions={[PERMISSIONS.MANAGE_CUSTOMERS]}
      to={"/companies"}
      label={"Companies"}
      icon={<CustomersIcon />}
    />
    <AuthzItem
      permissions={[PERMISSIONS.MANAGE_CUSTOMERS]}
      to={"/users"}
      label={"Users"}
      icon={<UsersIcon />}
    />
  </React.Fragment>
);

export const accountMenuItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Account
    </ListSubheader>
    <AuthzItem
      permissions={[PERMISSIONS.VIEW_PROFILE]}
      to={"/profile"}
      label={"Profile"}
      icon={<PeopleIcon />}
    />
  </React.Fragment>
);

export const settingsMenuItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Settings
    </ListSubheader>

    <AuthzItem
      permissions={[PERMISSIONS.VIEW_SETTINGS]}
      to={"/settings"}
      label={"Settings"}
      icon={<SettingsIcon />}
    />
  </React.Fragment>
);
