import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { InvoiceFullQuery } from "../../../generated/graphql";
import dayjs from "dayjs";
import theme from "../../../Theme";

interface InvoiceItemsTableProps {
  invoice: InvoiceFullQuery;
}

const InvoiceItemsTable: React.FC<InvoiceItemsTableProps> = ({ invoice }) => {
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ color: theme.palette.primary.main, fontSize: "16px" }}
                align="center"
              >
                Check IN/OUT
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main, fontSize: "16px" }}
                align="center"
              >
                Supplier
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main, fontSize: "16px" }}
                align="center"
              >
                Traveler
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main, fontSize: "16px" }}
              >
                Room Type
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main, fontSize: "16px" }}
              >
                Dayly Rate
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main, fontSize: "16px" }}
                align="center"
              >
                # Nights
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main, fontSize: "16px" }}
              >
                Total
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoice.invoice?.lines.map((item, index) => (
              <TableRow key={index}>
                <TableCell
                  sx={{ bgcolor: "#EEEEEE", fontSize: "16px" }}
                  align="center"
                >
                  {dayjs(item.checkIn).format("MM/DD/YYYY")} -{" "}
                  {dayjs(item.checkOut).format("MM/DD/YYYY")}
                </TableCell>
                <TableCell sx={{ bgcolor: "#EEEEEE", fontSize: "16px" }}>
                  {invoice.invoice?.supplier}
                </TableCell>
                <TableCell sx={{ bgcolor: "#EEEEEE", fontSize: "16px" }}>
                  {item.traveler}
                </TableCell>
                <TableCell sx={{ bgcolor: "#EEEEEE", fontSize: "16px" }}>
                  {item.roomType}
                </TableCell>
                <TableCell sx={{ bgcolor: "#EEEEEE", fontSize: "16px" }}>
                  $ {Number(item.dailyRate).toFixed(2)}
                </TableCell>
                <TableCell
                  sx={{ bgcolor: "#EEEEEE", fontSize: "16px" }}
                  align="center"
                >
                  {item.bookedNights}
                </TableCell>
                <TableCell sx={{ bgcolor: "#EEEEEE", fontSize: "16px" }}>
                  $ {Number(item.total).toFixed(2)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  width: "30%",
                  color: theme.palette.primary.main,
                  fontSize: "16px",
                }}
                align="center"
              >
                Attribute
              </TableCell>
              <TableCell
                sx={{
                  width: "10%",
                  color: theme.palette.primary.main,
                  fontSize: "16px",
                }}
              >
                Value
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main, fontSize: "16px" }}
                colSpan={4}
              >
                &nbsp;
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoice.invoice?.udids.map((udid, index) => (
              <TableRow key={index}>
                <TableCell
                  sx={{ bgcolor: "#EEEEEE", fontSize: "16px" }}
                  align="center"
                >
                  {udid.customerUDID.displayName}
                </TableCell>
                <TableCell sx={{ bgcolor: "#EEEEEE", fontSize: "16px" }}>
                  {udid.value}
                </TableCell>
                <TableCell
                  sx={{ bgcolor: "#EEEEEE", fontSize: "16px" }}
                  colSpan={4}
                >
                  &nbsp;
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default InvoiceItemsTable;
