import { useState } from "react";

import {
  Backdrop,
  Divider,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import IUsersSearchForm from "./IUsersSearchForm";
import IUsersTable from "./IUserTable";

import {
  useIUsersWhereQuery,
  IUserWhereInput,
  useDeleteOneIUserMutation,
  IUsersWhereQuery, // the generated type for the query result
  IUsersWhereQueryVariables, // the generated type for the query variables
  // other imports...
} from "../../../generated/graphql";
import { RefetchQueryDescriptor } from '@apollo/client';

const PAGE_SIZE = 50; // change this to your desired page size

type SearchParams = {
  name: string | null;
  email: string | null;
  lastName: string | null;
  dkNumber: string | null;
};

const hasParams = (params: SearchParams) => {
  // return true if any of the string params is not null or empty
  if (params.name !== null && params.name !== "") return true;
  if (params.lastName !== null && params.lastName !== "") return true;
  if (params.email !== null && params.email !== "") return true;
  if (params.dkNumber !== null && params.dkNumber !== "") return true;

  return true;
};

const buildWhereClause = (params: SearchParams) => {
  const whereClause: Partial<IUserWhereInput> = {};

  if (params.email) {
    whereClause.email = { startsWith: params.email };
  }

  if (params.name) {
    whereClause.name = { startsWith: params.name };
  }
  if (params.dkNumber) {
    whereClause.customers = {};
    whereClause.customers.some = {};
    whereClause.customers.some.dkNumber = {
      equals: params.dkNumber
    };
  }

  return whereClause;
};

const IUsersList = () => {


  const [searchParams, setSearchParams] = useState<SearchParams>({
    name: "",
    email: "",
    lastName: "",
    dkNumber: "",
  });

  // This state will hold the submitted search params
  const [submittedParams, setSubmittedParams] = useState<SearchParams>({
    name: "",
    email: "",
    lastName: "",
    dkNumber: "",
  });

  const [page, setPage] = useState(1);
  const handleSearch = (params: SearchParams) => {
    setPage(1);
    setSubmittedParams(params);
  };
  const loadNextPage = () => {
    if (page === totalPages) return;

    fetchMore({
      variables: {
        skip: (page + 1) * PAGE_SIZE,
        take: PAGE_SIZE,
      },
    });
    setPage(page + 1);
  };
  const loadPrevPage = () => {
    if (page === 1) return;

    fetchMore({
      variables: {
        skip: (page - 1) * PAGE_SIZE,
        take: PAGE_SIZE,
      },
    });
    setPage(page - 1);
  };

  const [deleteIUser, { loading: deleteLoading, error: deleteError }] = useDeleteOneIUserMutation({
    refetchQueries: [
      "IUsersWhere"
    ], 
    awaitRefetchQueries: true,
  });

  // Use submittedParams here, not searchParams
  const { data, loading, error, fetchMore } = useIUsersWhereQuery({
    variables: {
      where: buildWhereClause(submittedParams),
      skip: (page - 1) * PAGE_SIZE,
      take: PAGE_SIZE,
    },

    skip: hasParams(submittedParams) === false,
  });


  // Assuming data?.aggregateInvoice?._count?.number contains the total count of invoices
  const totalCount = data?.aggregateIUser._count?._all || 0;

  // Calculate total pages
  const totalPages = Math.ceil(totalCount / PAGE_SIZE);

  const handleUserDelete = async (id: number) => {
    try {
      // Attempt to delete the user
      //await deleteUser({ variables: { id: user.id } });
      const response = await deleteIUser({
        variables: {
          where: {
            id: id,
          },
        },
      });
      setSubmittedParams(searchParams);
    } catch (e) {
      // Handle any errors, e.g., by displaying a message to the user
      console.error(e);
    }
  };
  return (
    <Box sx={{ margin: 2, pt: 3, p: 1, minHeight: "80vh" }}>
      <Typography variant="h3" component="h1" gutterBottom>
        Users: Search
      </Typography>
      <Divider sx={{ my: 2, bgcolor: "secondary.main" }} />

      <IUsersSearchForm
        onSearch={handleSearch}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        loading={loading}
        error={error}
      />

      {!loading && data && (
        <IUsersTable
          IUsersData={data}
          handlePrevPage={loadPrevPage}
          handleNextPage={loadNextPage}
          deleteUser={handleUserDelete}
          currentPage={page}
          totalPages={totalPages}
        />
      )}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default IUsersList;
export type { SearchParams };
