import { CustomerUDID } from "../../../models/Customer";

const buildCreateUDIDsMany = (udides: CustomerUDID[]) => {

    const d = udides
        .filter(udid => udid.id < 0)
        .map(udid => (
            {
                udid: udid.udid,
                displayName: udid.displayName,
            }
        ));

    if (d.length > 0) {
        return {
            data: d
        };
    }

    return undefined;
};

const buildUpdateUDIDsMany = (udides: CustomerUDID[]) => {

    const d = udides
        .filter(udid => udid.id < 0)
        .map(udid => (
            {
                data: {
                    udid: { set: udid.udid },
                    displayName: { set: udid.displayName },
                },
                where: {
                    id: { equals: udid.id }
                }
            }
        ));

    if (d.length > 0) {
        d
    }

    return undefined;
};

const buildDeleteUDIDsMany = (udides: CustomerUDID[]) => {

    const deleteMany = udides.filter(udid => udid.id > 0).map(udid => (
        {
            id: { equals: udid.id }
        }
    ));

    return deleteMany;
}

export { buildCreateUDIDsMany, buildUpdateUDIDsMany, buildDeleteUDIDsMany }