import * as Apollo from '@apollo/client';
import { CreateOneIUserCustMutation, CreateOneIUserCustMutationVariables, UpdateOneIUserCustMutation, UpdateOneIUserCustMutationVariables } from "../../../generated/graphql";
import IUser from "../../../models/IUser";


const makeCreateIUserFn = (
    user: IUser,
    execCreateIUserFn: (d: Apollo.MutationHookOptions< CreateOneIUserCustMutation, CreateOneIUserCustMutationVariables>) => any
) => {

    if (!user.email || !user.lastName || !user.name) {
        throw new Error('A field is missing or invalid');
    }

    if (!user.password && user.id > 0 ) {
        throw new Error('Password is missing or invalid');
    }

    if (typeof user.password !== 'string' || user.password === "") {
        throw new Error('Password is missing or invalid');       
    }

    const rolesToConnect = user.roles?.map(r => ({ id: r.id }))    
    const custToConnect = user.customers?.map(i => ({dkNumber: i.dkNumber}))
    const gqlData = {
        variables: {
            data: {
                name: user.name,
                lastName: user.lastName,
                email: user.email,
                password: user.password,
                enabled: user.enabled,
                salt: "",
                roles: { connect: rolesToConnect },
                customers: { connect: custToConnect}
            }
        }
    }

    return async () => {

        const response = await execCreateIUserFn(gqlData)
        const newIUser = (response.data && isIUser(response.data.createOneIUser)) ?
            response.data.createOneIUser as IUser : undefined

        return { user: newIUser, errors: response.errors }

        
    }

}

const makeUpdateIUserFn = (
    user: IUser,
    origCustomers: IUser['customers'] | undefined,
    origRoles: IUser['roles'] | undefined,
    execUpdateIUserFn: (d: Apollo.MutationHookOptions<UpdateOneIUserCustMutation, UpdateOneIUserCustMutationVariables>) => any) => {

    // Build connect/disconnect arrays for roles
    const rolesToConnect = user.roles//.filter(r => !origRoles?.some(or => or.id === r.id))
    const rolesToDisconnect = origRoles?.filter(r => !user.roles?.some(ur => ur.id === r.id))
    const roles = {
        connect: rolesToConnect?.map(r => ({ id: r.id })),
        disconnect: rolesToDisconnect?.map(r => ({ id: r.id }))
    }

    const custToConnect = user.customers;
    const custToDisconnect = origCustomers;
    const c = {
        connect: custToConnect?.map(c => ({dkNumber: c.dkNumber})),
        disconnect: custToDisconnect?.map(c => ({id: c.id}))
    }

    const gqlData = {
        variables: {
            data: {
                name: { set: user.name },
                lastName: { set: user.lastName },
                email: { set: user.email },
                password: user.password ? { set: user.password } : undefined,
                enabled: { set: user.enabled },
                salt: { set: user.salt },                
                roles: roles,
                customers: c,
            },
            where: {
                id: user.id
            }
        },
    }

    return async () => {

        console.log(JSON.stringify(gqlData))

        const response = await execUpdateIUserFn(gqlData)

        const newIUser =
            (response.data && isIUser(response.data.updateOneIUser)) ?
                response.data.updateOneIUser as IUser : undefined

        return { user: newIUser, errors: response.errors }
    };
}

function isIUser(obj: any): obj is IUser {
    return obj && obj.id !== undefined && obj.name !== undefined;
}

export { makeCreateIUserFn, makeUpdateIUserFn}