import React, { useState } from "react";
import { Backdrop, CircularProgress, MenuItem, Select, Grid, TextField, Box, Button, Paper, Alert } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { SearchParams } from "./ETLFilesList";
import { ApolloError } from "@apollo/client";
import { useAuth } from "react-oidc-context";
import FileUpload from "../lib/FileUpload";

type ETLFilesearchFormProps = {
  onSearch: (params: SearchParams) => void;
  onUploadComplete: (filename: string) => void;
  searchParams: SearchParams;
  setSearchParams: React.Dispatch<React.SetStateAction<SearchParams>>;
  loading: boolean;
  error: ApolloError | undefined;
};

const ETLFilesearchForm: React.FC<ETLFilesearchFormProps> = ({ onSearch, onUploadComplete, setSearchParams, searchParams, loading, error }) => {

  const formRef = React.useRef<HTMLFormElement>(null);

  const [loading1, setLoading1] = useState(false);
  const auth = useAuth();
  const handleSubmit = (event: React.FormEvent) => {
    //event.preventDefault();
    onSearch(searchParams);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setSearchParams({
      ...searchParams,
      [name]: value,
    });
  };

  const handleDateChange = (date: Date | null, field: 'dateFrom' | 'dateTo') => {
    if (date) {
      setSearchParams(prev => ({
        ...prev,
        [field]: date
      }));
    }
  };

  if (loading) return <p>Loading...</p>;

  const apiUrl = process.env.REACT_APP_INVOICE_API_URL + '/api/uploadInvoicesCSV';

  return (
    <>
      <Paper elevation={1}>

        {error && <Alert severity="error">{error.message}</Alert>}

        <Box sx={{ display: 'block', alignItems: 'center', mt: 2, bgcolor: "#DFE6F4", padding: 3 }}>
          <form ref={formRef} noValidate autoComplete="off" onSubmit={handleSubmit} >

            <Grid container alignItems='left' spacing={1} >

              <Grid item xs={3}>
                <TextField
                  name="dataset" // must match the name of the property in the state
                  label="Dataset"
                  variant="outlined"
                  value={searchParams.dataset}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={2}>
                <DatePicker
                  label="From"
                  value={searchParams.dateFrom}
                  onChange={(date) => handleDateChange(date, 'dateFrom')}
                />
              </Grid>

              <Grid item xs={2}>
                <DatePicker
                  label="To"
                  value={searchParams.dateTo}
                  onChange={(date) => handleDateChange(date, 'dateTo')}
                />
              </Grid>

              <Grid item xs={1}>
                <Button type="submit" variant="contained" color="primary" >
                  Search
                </Button>
              </Grid>

              <Grid item xs={1}>
                <FileUpload onStart={() => { setLoading1(true) }} onEnd={(fName) => {
                  setLoading1(false);
                  setSearchParams({
                    ...searchParams,
                    dataset: fName
                  });
                  if (formRef.current) {
                    formRef.current.submit();
                  }

                }} apiUrl={apiUrl} accept=".csv" authz={auth.user?.access_token} />
              </Grid>

            </Grid>

          </form>
        </Box>
      </Paper>
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <CircularProgress />
      </Backdrop>
    </>
  );
};

export default ETLFilesearchForm;
