import React from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import Unauthorized from "../components/Unauthorized";

import { useAuthnAuthz } from "../hooks/Authorization";

interface AuthzProps {
    permissions: string[];
}

const Authorization: React.FC<AuthzProps> = ({ permissions }) => {

    const isAllowed = useAuthnAuthz();
    const [isAuthenticated, isAuthorized] = isAllowed(permissions);

    const location = useLocation();
    if (isAuthenticated) {
        return isAuthorized ? <Outlet /> : <Unauthorized />;
    }
    return <Navigate to="/login" state={{ path: location.pathname }} replace />;
};
export default Authorization;