import { useAuth, hasAuthParams } from "react-oidc-context";
import * as React from 'react';
import Loading from '../lib/Loading';

const Login: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const auth = useAuth();

    // automatically sign-in
    React.useEffect(() => {
        if (!hasAuthParams() &&
            !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
            auth.signinRedirect();
        }
    }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);

    return auth.isLoading 
        ? <Loading />  // Use your Loading component here
        : auth.isAuthenticated
        ? <>{children}</> // This will render the child components if the user is authenticated
        : null; // If not loading and not authenticated, render nothing
}

export default Login;
