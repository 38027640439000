import { Navigate, Route } from "react-router-dom";

import Profile from "../components/profile/Profile";
import Loading from "../components/lib/Loading";
import TppInvoices from "../components/invoice/tpp/Invoices";
import EltInvoices from "../components/invoice/elt/Invoices";
import IdxInvoices from "../components/invoice/InvoiceIdx";

import ETLInvoiceJobs from "../components/etljob/ETLInvoiceJobs";
import ETLFilesList from "../components/etlFiles/ETLFilesList";
import Settings from "../components/Settings";
import Authorization from "./Authorization";
import PERMISSIONS from "../permissions/Permissions";
import IUsersList from "../components/user/list/IUsersList";
import IUserEdit from "../components/user/edit/IUserEdit";
import CustomersList from "../components/customer/list/CustomersList";
import CustomerEdit from "../components/customer/edit/CustomerEdit";
import Dashboard from "../components/layout/Dashboard";
import PageNotFound from "../components/PageNotFound";

const AppRoutes = () => {
  return (
    <Route element={<Dashboard />}>
      <Route>
        <Route path="loading" element={<Loading />} />
      </Route>
      <Route
        element={
          <Authorization
            permissions={[
              PERMISSIONS.VIEW_TPP_INVOICES,
              PERMISSIONS.VIEW_TPP_OWN_INVOICES,
              PERMISSIONS.VIEW_ELT_OWN_INVOICES,
              PERMISSIONS.VIEW_ELT_INVOICES,
            ]}
          />
        }
      >
        <Route index element={<IdxInvoices />} />
      </Route>

      <Route
        element={<Authorization permissions={[PERMISSIONS.VIEW_PROFILE]} />}
      >
        <Route path="profile" element={<Profile />} />
      </Route>

      <Route
        element={<Authorization permissions={[PERMISSIONS.VIEW_SETTINGS]} />}
      >
        <Route path="settings" element={<Settings />} />
      </Route>

      <Route
        element={
          <Authorization
            permissions={[
              PERMISSIONS.VIEW_TPP_OWN_INVOICES,
              PERMISSIONS.VIEW_TPP_INVOICES,
            ]}
          />
        }
      >
        <Route path="tpp/invoices" element={<TppInvoices />} />
      </Route>
      <Route
        element={
          <Authorization
            permissions={[
              PERMISSIONS.VIEW_ELT_OWN_INVOICES,
              PERMISSIONS.VIEW_ELT_INVOICES,
            ]}
          />
        }
      >
        <Route path="elt/invoices" element={<EltInvoices />} />
      </Route>

      <Route element={<Authorization permissions={[PERMISSIONS.VIEW_JOBS]} />}>
        <Route path="etl-jobs" element={<ETLInvoiceJobs />} />
      </Route>

      <Route element={<Authorization permissions={[PERMISSIONS.VIEW_JOBS]} />}>
        <Route path="etl-files" element={<ETLFilesList />} />
      </Route>

      <Route
        path="companies"
        element={<Authorization permissions={[PERMISSIONS.VIEW_CUSTOMERS]} />}
      >
        <Route index element={<CustomersList />} />
      </Route>

      <Route
        path="company"
        element={<Authorization permissions={[PERMISSIONS.MANAGE_CUSTOMERS]} />}
      >
        <Route index element={<CustomerEdit />} />
        <Route path=":id" element={<CustomerEdit />} />
      </Route>

      <Route
        path="users"
        element={<Authorization permissions={[PERMISSIONS.VIEW_USERS]} />}
      >
        <Route index element={<IUsersList />} />
      </Route>

      <Route
        path="user"
        element={<Authorization permissions={[PERMISSIONS.MANAGE_USERS]} />}
      >
        <Route index element={<IUserEdit />} />
        <Route path=":id" element={<IUserEdit />} />
      </Route>

      <Route path="404" element={<PageNotFound />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Route>
  );
};

export default AppRoutes;
