import { useState } from "react";
import {
  SortOrder,
  useCustomersWhereQuery,
  CustomerWhereInput,
} from "../../../generated/graphql";

import {
  Backdrop,
  Divider,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import CustomerSearchForm from "./CustomerSearchForm";
import CustomerTable from "./CustomerTable";

const PAGE_SIZE = 50; // change this to your desired page size

type SearchParams = {
  dkNumber: string | null;
  name: string | null;
};

const hasParams = (params: SearchParams) => {
  // return true if any of the string params is not null or empty
  if (params.name !== null && params.name !== "") return true;
  if (params.dkNumber !== null && params.dkNumber !== "") return true;
  return true;
};

const buildWhereClause = (params: SearchParams) => {
  const whereClause: Partial<CustomerWhereInput> = {};

  if (params.dkNumber) {
    whereClause.dkNumber = { equals: params.dkNumber };
  }

  if (params.name) {
    whereClause.name = { startsWith: params.name };
  }

  return whereClause;
};

const Customers = () => {
  const [searchParams, setSearchParams] = useState<SearchParams>({
    dkNumber: "",
    name: "",
  });

  // This state will hold the submitted search params
  const [submittedParams, setSubmittedParams] = useState<SearchParams>({
    dkNumber: "",
    name: "",
  });

  const [page, setPage] = useState(1);
  const handleSearch = (params: SearchParams) => {
    // Only update submittedParams here, not searchParams
    setPage(1);
    setSubmittedParams(params);
  };
  const loadNextPage = () => {
    if (page === totalPages) return;

    fetchMore({
      variables: {
        skip: (page - 1) * PAGE_SIZE,
        take: PAGE_SIZE,
      },
    });
    setPage(page + 1);
  };

  const loadPrevPage = () => {
    if (page === 1) return;
    fetchMore({
      variables: {
        skip: (page - 1) * PAGE_SIZE,
        take: PAGE_SIZE,
      },
    });
    setPage(page - 1);
  };

  // Use submittedParams here, not searchParams
  const { data, loading, error, fetchMore } = useCustomersWhereQuery({
    variables: {
      where: buildWhereClause(submittedParams),
      orderBy: [{ dkNumber: SortOrder.Asc }],
      skip: (page - 1) * PAGE_SIZE,
      take: PAGE_SIZE,
    },

    skip: hasParams(submittedParams) === false,
  });

  // Assuming data?.aggregateCustomer?._count?.number contains the total count of customers
  const totalCount = data?.aggregateCustomer?._count?.id || 0;

  // Calculate total pages
  const totalPages = Math.ceil(totalCount / PAGE_SIZE);

  return (
    <Box sx={{ margin: 2, pt: 3, p: 1, minHeight: "80vh" }}>
      <Typography variant="h3" component="h1" gutterBottom>
        Company: Search
      </Typography>
      <Divider sx={{ my: 2, bgcolor: "secondary.main" }} />

      <CustomerSearchForm
        onSearch={handleSearch}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        loading={loading}
        error={error}
      />

      {!loading && data && (
        <CustomerTable
          customerData={data}
          handlePrevPage={loadPrevPage}
          handleNextPage={loadNextPage}
          currentPage={page}
          totalPages={totalPages}
        />
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress />
      </Backdrop>
    </Box>
  );
};

export default Customers;
export type { SearchParams };
