import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import {
  FormControlLabel,
  Button,
  Tabs,
  Tab,
  Divider,
  Checkbox,
  Backdrop,
  CircularProgress,
  Box,
  Grid,
  IconButton,
  TextField,
  Alert,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  useRolesQuery,
  useIUserFullQuery,
  useUpdateOneIUserCustMutation,
  useCreateOneIUserCustMutation,
} from "../../../generated/graphql";
import IUser, { IUserRole } from "../../../models/IUser";
import Role from "../../../models/Role";
import Customer from "../../../models/Customer";
import DeleteIcon from "@mui/icons-material/DeleteForeverOutlined";

import { makeCreateIUserFn, makeUpdateIUserFn } from "./IUserUtils";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

type UIRole = {
  name: string;
  id: number;
  connected: boolean;
};

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1, mt: 1 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const IUserForm: React.FC = () => {
  const {
    data: rolesData,
    loading: rolesLoading,
    error: rolesError,
  } = useRolesQuery();
  const [allRoles, setAllRoles] = useState<Role[]>([]);
  const [uiRoles, setUIRoles] = useState<UIRole[]>([]);
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>("");

  useEffect(() => {
    if (rolesData && rolesData.roles) {
      setAllRoles(rolesData.roles);
      setUIRoles(
        rolesData.roles.map((role) => ({
          name: role.name,
          id: role.id,
          connected: user.roles
            ? user.roles.some((r) => r.id === role.id)
            : false,
        })),
      );
    }
  }, [rolesData]);

  // Mutatio error
  const [mutationError, setMutationError] = useState<string | null>(null);
  const [mutationOK, setMutationOk] = useState<string | null>(null);

  // Using useParams hook to get the id parameter from the URL
  const { id } = useParams<{ id: string }>();
  const [updateIUser, { loading: updateLoading, error: updateError }] =
    useUpdateOneIUserCustMutation({
      refetchQueries: [
        "IUsersWhere"
      ], 
      awaitRefetchQueries: true,
    });
  const [createIUser, { loading: createLoading, error: createError }] =
    useCreateOneIUserCustMutation({
      refetchQueries: [
        "IUsersWhere"
      ], 
      awaitRefetchQueries: true,
    });

  // Load user using graphql
  const { data, loading, error } = useIUserFullQuery({
    variables: {
      where: { id: parseInt(id ? id : "-1") },
    },
  });

  const d = data?.iUser?.customers ? data.iUser.customers.map(c => c.dkNumber) : [];

  // IUser state (create a type)
  const [customers, setCustomers] = useState<string[]>(d ? d : []);
  const [origRoles, setOrigRoles] = useState<IUserRole[]>([]);
  const [origCustomers, setOrigCustomers] = useState<{ name: string; id: number; dkNumber: string; }[]>([]);

  const [user, setIUser] = useState<IUser>({
    id: -1,
    email: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    password: "",
    salt: "",
    roles: [],
    name: "",
    lastName: "",
    customerId: null,
    customer: null,
    enabled: true,
  });

  // If data?.customer is populated, set the customer
  useEffect(() => {
    if (data?.iUser) {
      try {
        const d = data?.iUser?.customers ? data.iUser.customers.map(c => c.dkNumber) : [];
        setIUser(data.iUser);
        setOrigRoles(data.iUser.roles || []);
        setOrigCustomers(data.iUser.customers || []);
        setUIRoles(
          allRoles.map((role) => ({
            name: role.name,
            id: role.id,
            connected: data.iUser?.roles
              ? data.iUser.roles.some((r) => r.id === role.id)
              : false,
          })),
        );
        setCustomers(d);
      } finally {
      }
    }
  }, [data]);

  const handleAddCustomer = () => {
    // Add a new customer to the user

    const c = user.customers ? user.customers : [];
    setIUser(
      {
        ...user,
        customers: [...c, { id: -1, dkNumber: "" }]
      }
    )
  }

  const handleDeleteCustomer = (index: number) => {

    const c = user.customers? user.customers : [];
    const updatedCustomers = c.filter(
      (_, idx) => idx !== index,
    );
    // Update the customer state with the new list of addresses
    setIUser((prevUser) => ({
      ...prevUser,
      customers: updatedCustomers,
    }));
  };


  const customerFieldIsMissing = (field: string, idx: number) => {
    const r =  mutationError !== null && user.customers && user.customers[idx][field] === "";
    return r != null && r;
  };

  const customerFieldRequiredMsg = (field: string, idx: number) => {
    return mutationError !== null && user.customers && 
      user.customers[idx] &&
      user.customers[idx][field] === ""
      ? "Required"
      : "";
  };


  const handleCustomerInputChange = (
    index: number,
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {

    const { name, value } = event.target;

      // Update the specific address using type assertion
      const updatedCustomers = user.customers ? [...user.customers] : [];
      updatedCustomers[index] = {
        ...updatedCustomers[index],
        [name as keyof Customer]: value,
      };

      // Update the customer local state
      setIUser((prevUser) => ({
        ...prevUser,
        customers: updatedCustomers,
      }));

  }

  // Handle changes to the form
  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { name, value } = e.target;
    // TODO : Manage DK Numbers

    if (name === "confirm") {
      setPasswordConfirmation(value);
      return;
    }

    if (name === "enabled") {
      setIUser((prev) => ({ ...prev, enabled: !prev.enabled }));
      return;
    }

    setIUser((prev) => ({ ...prev, [name]: value }));
  };

  const handleRoleChange = (roleId: number) => {
    // replace UI roles with new options
    const ur: UIRole[] = uiRoles.map((role) => ({
      name: role.name,
      id: role.id,
      connected: role.id === roleId ? !role.connected : role.connected,
    }));

    setUIRoles(ur);
  };

  // Handle submit: save
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (user.id <= 0 && user.password !== passwordConfirmation) {
      setMutationError("Password does not match the confirmation");
      return;
    }

    try {
      // Build user object
      const userRoles = uiRoles
        .filter((r) => r.connected)
        .map((r) => ({ id: r.id, name: r.name }));
      const dbUser = { ...user, roles: userRoles, dkNumber: customers };

      // clear mutation error
      setMutationError(null);
      setMutationOk(null);
      const response = await (user.id > 0
        ? makeUpdateIUserFn(dbUser, origCustomers, origRoles, updateIUser)
        : makeCreateIUserFn(dbUser, createIUser))();
      // Check for error
      if (response.errors) {
        setMutationError(response.errors[0].message);
        return;
      }
      if (response.user) {
        setIUser(response.user);
      }
      setMutationOk("User saved");
    } catch (error) {
      // If there's a network or other kind of error.
      setMutationError((error as Error).message);
    }
  };

  // Tab management
  const [tab1Value, setTab1Value] = React.useState(0);
  const handleTab1Change = (event: React.SyntheticEvent, newValue: number) => {
    setTab1Value(newValue);
  };

  const [tab2Value, setTab2Value] = React.useState(0);
  const handleTab2Change = (event: React.SyntheticEvent, newValue: number) => {
    setTab2Value(newValue);
  };

  const fieldIsMissing = (field: string) => {
    return mutationError !== null && user[field] === "";
  };

  const fieldRequiredMsg = (field: string) => {
    return mutationError !== null && user[field] === "" ? "Required" : "";
  };

  return (
    <Box sx={{ margin: 2, pt: 1, minHeight: "80vh" }}>
      {!(loading || updateLoading || createLoading || rolesLoading) && (
        <>
          <Typography variant="h3" component="h1" gutterBottom>
            User : {user.id > 0 ? user.email : "New"}
          </Typography>
          <Divider sx={{ my: 2, bgcolor: "secondary.main" }} />

          {error && <Alert severity="error">{error.message}</Alert>}
          {mutationError && <Alert severity="error">{mutationError}</Alert>}
          {mutationOK && <Alert severity="success">{mutationOK}</Alert>}
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container>
              <Grid item sm={3}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={tab1Value}
                    onChange={handleTab1Change}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Details" {...a11yProps(0)} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={tab1Value} index={0}>
                  <Box>
                    <Grid container>
                      <Grid item sm={12} sx={{ mt: 1, mb: 1 }}>
                        <Grid container columns={6}>
                          <Grid item sm={4} sx={{ mr: 3 }}>
                            <TextField
                              variant="outlined"
                              label="Email (login)"
                              required
                              placeholder="Email (login)"
                              name="email"
                              value={user.email}
                              onChange={handleInputChange}
                              error={fieldIsMissing("email")}
                              helperText={fieldRequiredMsg("email")}
                              fullWidth
                            />
                          </Grid>
                          <Grid item sm={1}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="enabled"
                                  checked={user.enabled}
                                  onChange={handleInputChange}
                                />
                              }
                              label="Active"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item sm={12} sx={{ mt: 1, mb: 1 }}>
                        <TextField
                          variant="outlined"
                          label="Name"
                          required
                          placeholder="Name"
                          name="name"
                          value={user.name}
                          error={fieldIsMissing("name")}
                          helperText={fieldRequiredMsg("name")}
                          onChange={handleInputChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item sm={12} sx={{ mt: 1, mb: 1 }}>
                        <TextField
                          variant="outlined"
                          label="Last Name"
                          required
                          placeholder="Last Name"
                          name="lastName"
                          value={user.lastName}
                          error={fieldIsMissing("lastName")}
                          helperText={fieldRequiredMsg("lastName")}
                          onChange={handleInputChange}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Button type="submit" variant="contained" color="primary">
                      Save
                    </Button>
                  </Box>
                </CustomTabPanel>
              </Grid>
              <Grid item sm={9}>
                <Box sx={{ ml: 5, borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={tab2Value}
                    onChange={handleTab2Change}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Companies" {...a11yProps(1)} />
                    <Tab label="Roles" {...a11yProps(2)} />
                    <Tab label="Security" {...a11yProps(3)} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={tab2Value} index={0}>
                  <Box>
                    {user.customers?.map((customer, index) => (
                      <Grid
                        container
                        columns={12}
                        sx={{ ml: 5, alignItems: "left", justifyContent: "left" }}
                        key={index}
                      >
                        <Grid item sx={{ mt: 1, mb: 1 }}>
                          <TextField
                            variant="outlined"
                            label="DK #"
                            required
                            placeholder="DK #"
                            name="dkNumber"
                            value={customer.dkNumber}
                            onChange={(e) => handleCustomerInputChange(index, e)}
                            error={customerFieldIsMissing("dkNumber", index)}
                            helperText={customerFieldRequiredMsg("dkNumber", index)}
                            fullWidth
                          />

                        </Grid>
                        <Grid item sm={1} sx={{ mt: 1, mb: 1 , alignItems: "baseline" }}>
                          <Tooltip title="Delete Company ...">
                            <IconButton
                              onClick={() => handleDeleteCustomer(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>))}
                    <Grid container sx={{ ml: 5, alignItems: "left", justifyContent: "left" }}>
                      <Grid>
                        <Button variant="contained"
                          component="label"
                          sx={{ bgcolor: "#C0B7A4", color: "white" }}
                          onClick={handleAddCustomer}>
                          Add ...
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </CustomTabPanel>
                <CustomTabPanel value={tab2Value} index={1}>
                  <Box>
                    {/* Assuming you have a list of all roles in a variable named allRoles */}
                    {uiRoles.map((role, index) => (
                      <Grid
                        container
                        columns={24}
                        sx={{
                          ml: 5,
                          alignItems: "left",
                          justifyContent: "left",
                        }}
                        key={index}
                      >
                        <Grid item sm={1} sx={{ mt: 1, mb: 1, mr: 1 }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={role.connected}
                                onChange={() => handleRoleChange(role.id)}
                              />
                            }
                            label={role.name}
                          />
                        </Grid>
                      </Grid>
                    ))}
                  </Box>
                </CustomTabPanel>
                <CustomTabPanel value={tab2Value} index={2}>
                  <Box>
                    <Grid
                      container
                      columns={12}
                      sx={{ ml: 5, alignItems: "left", justifyContent: "left" }}
                    >
                      <Grid item sm={12} sx={{ mt: 1, mb: 1 }}>
                        <TextField
                          variant="outlined"
                          label="Password"
                          placeholder="Password"
                          name="password"
                          type="password"
                          value={user.password}
                          required={user.id <= 0}
                          error={user.id <= 0 && fieldIsMissing("password")}
                          helperText={
                            user.id <= 0 && fieldRequiredMsg("password")
                          }
                          onChange={handleInputChange}
                        />

                      </Grid>

                      <Grid item sm={12} sx={{ mt: 1, mb: 1 }}>
                        <TextField
                          variant="outlined"
                          label="Confirmation"
                          placeholder="Confirm"
                          type="password"
                          name="confirm"
                          value={passwordConfirmation}
                          required={user.id <= 0}
                          error={
                            user.id <= 0 &&
                            mutationError !== null &&
                            passwordConfirmation === ""
                          }
                          helperText={
                            user.id <= 0 &&
                              mutationError !== null &&
                              passwordConfirmation
                              ? "Required"
                              : ""
                          }
                          onChange={handleInputChange}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </CustomTabPanel>
              </Grid>
            </Grid>
          </form>
        </>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading || updateLoading || createLoading || rolesLoading}
      >
        <CircularProgress />
      </Backdrop>
    </Box>
  );
};

export default IUserForm;
