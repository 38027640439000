import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { CustomerFullQuery } from "../../../generated/graphql";
import dayjs from "dayjs";
import theme from "../../../Theme";

interface CustomerDetailsTableProps {
  customer: CustomerFullQuery;
}

const addressAsString = function (address: {
  id?: number;
  name?: string;
  postal?: string;
  state: any;
  street: any;
  city: any;
  code?: string;
  country: any;
  customerId?: number;
  zipCode?: any;
}) {
  return `${address.street}, ${address.city}, ${address.state}, ${address.postal} - ${address.country} `;
};

const addressNameAsCode = function (name: string) {
  const dashIndex = name.indexOf("-");
  if (dashIndex !== -1) {
    return name.substring(dashIndex + 1);
  }
  return name;
};

const CustomerDetailsTable: React.FC<CustomerDetailsTableProps> = ({
  customer,
}) => {
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ color: theme.palette.primary.main, fontSize: "16px" }}
              >
                Address
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main, fontSize: "16px" }}
              >
                Value
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customer.customer?.addresses.map((address, index) => (
              <TableRow key={index}>
                <TableCell sx={{ bgcolor: "#EEEEEE", fontSize: "16px" }}>
                  {addressNameAsCode(address.code)}
                </TableCell>
                <TableCell sx={{ bgcolor: "#EEEEEE", fontSize: "16px" }}>
                  {addressAsString(address)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ color: theme.palette.primary.main, fontSize: "16px" }}
              >
                UDID
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main, fontSize: "16px" }}
              >
                Invoice Label
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customer.customer?.udids.map((udid, index) => (
              <TableRow key={index}>
                <TableCell sx={{ bgcolor: "#EEEEEE", fontSize: "16px" }}>
                  {udid.udid}
                </TableCell>
                <TableCell sx={{ bgcolor: "#EEEEEE", fontSize: "16px" }}>
                  {udid.displayName}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CustomerDetailsTable;
