import React from "react";
import { useAuthnAuthz } from "../../hooks/Authorization";
import PERMISSIONS from "../../permissions/Permissions";
import TppInvoices from "./tpp/Invoices";
import EltInvoices from "./elt/Invoices";

const Invoices = () => {
  const checkPermissions = useAuthnAuthz();

  // Check TPP permissions
  const [isTppAuthenticated, isTppAuthorized] = checkPermissions([
    PERMISSIONS.VIEW_TPP_OWN_INVOICES,
    PERMISSIONS.VIEW_TPP_INVOICES,
  ]);

  // Check ELT permissions if TPP permissions are not granted
  const [isEltAuthenticated, isEltAuthorized] = !isTppAuthorized
    ? checkPermissions([
        PERMISSIONS.VIEW_ELT_INVOICES,
        PERMISSIONS.VIEW_ELT_OWN_INVOICES,
      ])
    : [false, false];

  if (isTppAuthorized) {
    return <TppInvoices />;
  } else if (isEltAuthorized) {
    return <EltInvoices />;
  } else {
    // Handle the case where neither permission set is authorized
    return <div>No permissions to view any invoices</div>;
  }
};

export default Invoices;
