import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  TextField,
  Box,
  Button,
  Paper,
  Alert,
} from "@mui/material";
import { SearchParams } from "./IUsersList";
import { ApolloError } from "@apollo/client";

type IUsersearchFormProps = {
  onSearch: (params: SearchParams) => void;
  searchParams: SearchParams;
  setSearchParams: React.Dispatch<React.SetStateAction<SearchParams>>;
  loading: boolean;
  error: ApolloError | undefined;
};

const IUsersSearchForm: React.FC<IUsersearchFormProps> = ({
  onSearch,
  setSearchParams,
  searchParams,
  loading,
  error,
}) => {
  const navigate = useNavigate();
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onSearch(searchParams);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setSearchParams({
      ...searchParams,
      [name]: value,
    });
  };
  
  const handleNewIUser = () => {
    // Navigate to new customer page
    navigate("/user/");
  };

  return (
    <>
      <Paper elevation={1}>
        {error && <Alert severity="error">{error.message}</Alert>}

        <Box
          sx={{
            display: "block",
            alignItems: "center",
            mt: 2,
            bgcolor: "#DFE6F4",
            padding: 3,
          }}
        >
          <form
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <Grid container alignItems="left" spacing={1}>
              <Grid item xs={3}>
                <TextField
                  name="name" // must match the name of the property in the state
                  label="Name"
                  variant="outlined"
                  value={searchParams.name}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="dkNumber"
                  label="Company (DK)"
                  variant="outlined"
                  fullWidth
                  value={searchParams.dkNumber}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="email"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  value={searchParams.email}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={1}>
                <Button type="submit" variant="contained" color="primary">
                  Search
                </Button>
              </Grid>

              <Grid item xs={1}>
                <Button
                  variant="contained"
                  component="label"
                  sx={{ bgcolor: "#C0B7A4", color: "white" }}
                  onClick={handleNewIUser}
                >
                  New ...
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Paper>
    </>
  );
};

export default IUsersSearchForm;
