import React, { useState, useEffect } from 'react';

import { Button, Tabs, Tab, Divider, Checkbox, Backdrop, CircularProgress, Box, Grid, TextField, Alert, Typography } from '@mui/material'
import { useRolesQuery, useIUserFullQuery, useUpdateOneIUserCustMutation, useCreateOneIUserCustMutation } from '../../generated/graphql';
import IUser, { IUserRole } from '../../models/IUser';
import Role from '../../models/Role';
import { useAuth } from "react-oidc-context";
import { useAuthz, PERMISSIONS } from "../../hooks/Authorization";
import Unauthorized from "../Unauthorized";

import { makeCreateIUserFn, makeUpdateIUserFn } from '../user/edit/IUserUtils';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

type UIRole = {
    name: string,
    id: number,
    connected: boolean;
};

function ClaimLabel(claim: string) {
    if (claim === 'email') {
        return 'Email'
    } else if (claim === 'sub') {
        return 'Subject'
    } else if (claim === 'groups') {
        return 'Groups'
    } else if (claim === 'first_name') {
        return 'First Name'
    } else if (claim === 'last_name') {
        return 'Last Name'
    } else if (claim === 'josso.user.dn') {
        return 'DN'
    } else {
        return claim
    }
};


function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1, mt: 1 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface ProfileProps {
}

const IUserForm: React.FC<ProfileProps> = ({ }) => {

    const auth = useAuth();
    const isAllowed = useAuthz();

    if (auth.isLoading) {
        return <div>Loading...</div>;

    } else if (auth.error) {
        return <div>Oops... {auth.error.message}</div>;

    } else if (auth.isAuthenticated) {
        if (!isAllowed([PERMISSIONS.VIEW_PROFILE])) {
            return <Unauthorized />
        }
    }

    // Get username

    const idTokenClaims = auth.user?.profile
    if (idTokenClaims === undefined) {
        return <div>Oops... no profile information</div>;
    }


    const { data: rolesData, loading: rolesLoading, error: rolesError } = useRolesQuery();
    const [allRoles, setAllRoles] = useState<Role[]>([])
    const [uiRoles, setUIRoles] = useState<UIRole[]>([])
    const [passwordConfirmation, setPasswordConfirmation] = useState<string>("");

    useEffect(() => {
        if (rolesData && rolesData.roles) {
            setAllRoles(rolesData.roles);
            setUIRoles(rolesData.roles.map(role => ({
                name: role.name,
                id: role.id,
                connected: user.roles ? user.roles.some(r => r.id === role.id) : false,
            })))
        }
    }, [rolesData]);

    // Mutatio error
    const [mutationError, setMutationError] = useState<string | null>(null);

    // Using useParams hook to get the id parameter from the URL

    const [updateIUser, { loading: updateLoading, error: updateError }] = useUpdateOneIUserCustMutation();
    const [createIUser, { loading: createLoading, error: createError }] = useCreateOneIUserCustMutation();

    // Load user using graphql
    const { data, loading, error, refetch } = useIUserFullQuery({
        variables: {
            where: { email: idTokenClaims.sub ? idTokenClaims.sub : "NA" }
        }
    });

    const d: string = data?.iUser?.customers ? data.iUser.customers.map(c => c.dkNumber).join(',') : "";

    // IUser state (create a type)
    const [dkNumbers, setDkNumbers] = useState<string>(d ? d : "");
    const [origRoles, setOrigRoles] = useState<IUserRole[]>([])
    const [user, setIUser] = useState<IUser>({
        id: -1,
        email: '',
        createdAt: new Date(),
        updatedAt: new Date(),
        password: '',
        salt: '',
        roles: [],
        name: '',
        lastName: '',
        customerId: null,
        customer: null,
        enabled: true,

    });

    // If data?.customer is populated, set the customer
    useEffect(() => {
        if (data?.iUser) {
            const d = data.iUser.customers ? data.iUser.customers.map(c => c.dkNumber).join(',') : "";
            setIUser(data.iUser);
            setOrigRoles(data.iUser.roles || []);
            setUIRoles(allRoles.map(role => ({
                name: role.name,
                id: role.id,
                connected: data.iUser?.roles ? data.iUser.roles.some(r => r.id === role.id) : false,
            })))
            setDkNumbers(d ? d : "");

        }
    }, [data]);


    // Handle changes to the form
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        if (name === "dkNumber") {
            // Add/Update the customer
            setDkNumbers(value);
            return;
        }

        if (name === "confirm") {
            setPasswordConfirmation(value);
            return;
        }

        if (name === "enabled") {
            setIUser(prev => ({ ...prev, enabled: !prev.enabled }));
            return;
        }

        setIUser(prev => ({ ...prev, [name]: value }));
    };

    // Handle submit: save
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if ((user.password || passwordConfirmation) && user.password !== passwordConfirmation) {
            setMutationError("Password does not match the confirmation");
            return;
        }

        try {

            // Build user object
            const userRoles = uiRoles.filter(r => r.connected).map(r => ({ id: r.id, name: r.name }));
            const dbUser = { ...user, roles: userRoles, dkNumber: dkNumbers };

            // clear mutation error
            setMutationError(null);
            const response = await makeUpdateIUserFn(dbUser, undefined, undefined, updateIUser)() 
            // Check for error
            if (response.errors) {
                setMutationError(response.errors[0].message);
                return;
            }
            if (response.user) {
                setIUser(response.user)
            }

        } catch (error) {
            // If there's a network or other kind of error.
            setMutationError((error as Error).message);
        }

    };

    // Tab management
    const [tab1Value, setTab1Value] = React.useState(0);
    const handleTab1Change = (event: React.SyntheticEvent, newValue: number) => {
        setTab1Value(newValue);
    };

    const [tab2Value, setTab2Value] = React.useState(0);
    const handleTab2Change = (event: React.SyntheticEvent, newValue: number) => {
        setTab2Value(newValue);
    };

    const fieldIsMissing = (field: string) => {
        return mutationError !== null && user[field] === "";
    }

    const fieldRequiredMsg = (field: string) => {
        return mutationError !== null && user[field] === "" ? "Required" : "";
    }

    return (
        <Box sx={{ margin: 2, pt: 1, minHeight: '80vh' }}>
            {user && !(loading || updateLoading || createLoading || rolesLoading) && <>
                <Typography variant="h3" component="h1" gutterBottom>
                    User : {user.id > 0 ? user.email : "New"}
                </Typography>
                <Divider sx={{ my: 2, bgcolor: 'secondary.main' }} />

                {error && <Alert severity="error">{error.message}</Alert>}
                {mutationError && <Alert severity="error">{mutationError}</Alert>}
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <Grid container>
                        <Grid item sm={3}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={tab1Value} onChange={handleTab1Change} aria-label="basic tabs example">
                                    <Tab label="Details" {...a11yProps(0)} />
                                </Tabs>
                            </Box>
                            <CustomTabPanel value={tab1Value} index={0}>
                                <Box>
                                    <Grid container>

                                        <Grid item sm={12} sx={{ mt: 1, mb: 1 }}>
                                            <Grid container columns={6}>
                                                <Grid item sm={4} sx={{ mr: 3 }} >
                                                    <TextField variant="outlined"
                                                        label="Email (login)"
                                                        required
                                                        disabled
                                                        placeholder="Email (login)"
                                                        name="email"
                                                        value={user.email}
                                                        onChange={handleInputChange}
                                                        error={fieldIsMissing("email")}
                                                        helperText={fieldRequiredMsg("email")}
                                                        fullWidth />

                                                </Grid>

                                            </Grid>

                                        </Grid>
                                        <Grid item sm={12} sx={{ mt: 1, mb: 1 }}>
                                            <TextField
                                                variant="outlined"
                                                label="Name"
                                                required
                                                placeholder="Name"
                                                name="name"
                                                value={user.name}
                                                error={fieldIsMissing("name")}
                                                helperText={fieldRequiredMsg("name")}
                                                onChange={handleInputChange}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item sm={12} sx={{ mt: 1, mb: 1 }}>
                                            <TextField
                                                variant="outlined"
                                                label="Last Name"
                                                required
                                                placeholder="Last Name"
                                                name="lastName"
                                                value={user.lastName}
                                                error={fieldIsMissing("lastName")}
                                                helperText={fieldRequiredMsg("lastName")}
                                                onChange={handleInputChange}
                                                fullWidth />
                                        </Grid>

                                    </Grid>
                                    <Button type="submit" variant="contained" color="primary" >
                                        Save
                                    </Button>
                                </Box>
                            </CustomTabPanel>
                        </Grid>
                        <Grid item sm={9}>
                            <Box sx={{ ml: 5, borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={tab2Value} onChange={handleTab2Change} aria-label="basic tabs example">
                                    <Tab label="Security" {...a11yProps(1)} />
                                    <Tab label="Session" {...a11yProps(2)} />

                                </Tabs>
                            </Box>

                            <CustomTabPanel value={tab2Value} index={0}>
                                <Box>
                                    <Grid container columns={12} sx={{ ml: 5, alignItems: 'left', justifyContent: 'left' }} >
                                        <Grid item sm={12} sx={{ mt: 1, mb: 1 }}>
                                            <TextField
                                                variant="outlined"
                                                label="Password"
                                                placeholder="Password"
                                                name="password"
                                                type="password"
                                                value={user.password}
                                                required={user.id <= 0}
                                                error={user.id <= 0 && fieldIsMissing("password")}
                                                helperText={user.id <= 0 && fieldRequiredMsg("password")}
                                                onChange={handleInputChange}
                                            />

                                        </Grid>

                                        <Grid item sm={12} sx={{ mt: 1, mb: 1 }}>
                                            <TextField
                                                variant="outlined"
                                                label="Confirmation"
                                                placeholder="Confirm"
                                                type="password"
                                                name="confirm"
                                                value={passwordConfirmation}
                                                required={user.id <= 0}
                                                error={user.id <= 0 && mutationError !== null && passwordConfirmation === ""}
                                                helperText={user.id <= 0 && mutationError !== null && passwordConfirmation ? "Required" : ""}
                                                onChange={handleInputChange}
                                            />

                                        </Grid>
                                    </Grid>
                                </Box>

                            </CustomTabPanel>

                            <CustomTabPanel value={tab2Value} index={1}>
                                <Box>
                                    <Grid container columns={12} sx={{ ml: 5, alignItems: 'left', justifyContent: 'left' }} >

                                        {Object.keys(idTokenClaims).filter((key) => key !== 'dn').sort().map(
                                            (key) => (

                                        <Grid item sm={12} sx={{ mt: 1, mb: 1 }}>
                                            <TextField
                                                variant="outlined"
                                                label={ClaimLabel(key)}
                                                placeholder={ClaimLabel(key)}
                                                name={key}
                                                value={idTokenClaims[key]}
                                                disabled
                                                fullWidth />
                                        </Grid>
                                            )
                                        )}


                                        <Grid item sm={12} sx={{ mt: 1, mb: 1 }}>

                                        </Grid>

                                        <Grid item sm={12} sx={{ mt: 1, mb: 1 }}>

                                        </Grid>
                                    </Grid>
                                </Box>

                            </CustomTabPanel>
                        </Grid>
                    </Grid>
                </form >
            </>}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading || updateLoading || createLoading || rolesLoading}
            >
                <CircularProgress />
            </Backdrop>
        </Box >
    );
}

export default IUserForm;


