import React from "react";
import {
  CircularProgress,
  MenuItem,
  Select,
  Grid,
  TextField,
  Box,
  Button,
  Paper,
  Alert,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { SearchParams } from "./ETLInvoiceJobs";
import { ApolloError } from "@apollo/client";

type ETLInvoiceJobSearchFormProps = {
  onSearch: (params: SearchParams) => void;
  searchParams: SearchParams;
  setSearchParams: React.Dispatch<React.SetStateAction<SearchParams>>;
  loading: boolean;
  error: ApolloError | undefined;
};

const ETLInvoiceJobSearchForm: React.FC<ETLInvoiceJobSearchFormProps> = ({
  onSearch,
  setSearchParams,
  searchParams,
  loading,
  error,
}) => {
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onSearch(searchParams);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setSearchParams({
      ...searchParams,
      [name]: value,
    });
  };

  const handleDateChange = (
    date: Date | null,
    field: "dateFrom" | "dateTo",
  ) => {
    if (date) {
      setSearchParams((prev) => ({
        ...prev,
        [field]: date,
      }));
    }
  };

  if (loading)
    return (
      <p>
        Loading...
        <CircularProgress />
      </p>
    );

  return (
    <>
      <Paper elevation={1}>
        {error && <Alert severity="error">{error.message}</Alert>}

        <Box
          sx={{
            display: "block",
            alignItems: "center",
            mt: 2,
            bgcolor: "#DFE6F4",
            padding: 3,
          }}
        >
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container alignItems="left" spacing={1}>
              <Grid item xs={1}>
                <TextField
                  name="number" // must match the name of the property in the state
                  label="Job #"
                  variant="outlined"
                  value={searchParams.number}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  name="dataset" // must match the name of the property in the state
                  label="Dataset"
                  variant="outlined"
                  value={searchParams.dataset}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={2}>
                <DatePicker
                  label="From"
                  value={searchParams.dateFrom}
                  onChange={(date) => handleDateChange(date, "dateFrom")}
                />
              </Grid>

              <Grid item xs={2}>
                <DatePicker
                  label="To"
                  value={searchParams.dateTo}
                  onChange={(date) => handleDateChange(date, "dateTo")}
                />
              </Grid>

              <Grid item xs={1}>
                <Box width={100}>
                  {" "}
                  {/* Set the width to the desired value */}
                  <Select
                    value={searchParams.status}
                    onChange={(event) =>
                      handleInputChange({
                        target: {
                          name: event.target.name,
                          value: event.target.value,
                        },
                      } as React.ChangeEvent<HTMLInputElement>)
                    }
                    variant="outlined"
                    fullWidth
                    name="status"
                  >
                    <MenuItem value="ALL">ALL</MenuItem>
                    <MenuItem value="OK">OK</MenuItem>
                    <MenuItem value="ERROR">ERROR</MenuItem>
                  </Select>
                </Box>
              </Grid>

              <Grid item xs={1}>
                <Button type="submit" variant="contained" color="primary">
                  Search
                </Button>
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
          </form>
        </Box>
      </Paper>
    </>
  );
};

export default ETLInvoiceJobSearchForm;
