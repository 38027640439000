import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  TextField,
  Box,
  List,
  Button,
  Paper,
  Alert,
} from "@mui/material";
import { SearchParams } from "./CustomersList";
import { ApolloError } from "@apollo/client";

type CustomerSearchFormProps = {
  onSearch: (params: SearchParams) => void;
  searchParams: SearchParams;
  setSearchParams: React.Dispatch<React.SetStateAction<SearchParams>>;
  loading: boolean;
  error: ApolloError | undefined;
};

const CustomerSearchForm: React.FC<CustomerSearchFormProps> = ({
  onSearch,
  setSearchParams,
  searchParams,
  loading,
  error,
}) => {
  const navigate = useNavigate();
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onSearch(searchParams);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setSearchParams({
      ...searchParams,
      [name]: value,
    });
  };

  const handleNewCustotmer = () => {
    // Navigate to new customer page
    navigate("/company/");
  };

  return (
    <Paper elevation={1}>
      {error && <Alert severity="error">{error.message}</Alert>}

      <Box sx={{ alignItems: "center", mt: 2, bgcolor: "#DFE6F4", padding: 3 }}>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item sm={1}>
              <TextField
                name="dkNumber"
                label="DK #"
                variant="outlined"
                fullWidth
                value={searchParams.dkNumber}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item sm={4}>
              <TextField
                name="name"
                label="Name"
                variant="outlined"
                fullWidth
                value={searchParams.name}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item sm={5}></Grid>

            <Grid item sm={1}>
              <Button type="submit" variant="contained" color="primary">
                Search
              </Button>
            </Grid>

            <Grid item sm={1}>
              <Button
                variant="contained"
                component="label"
                sx={{ bgcolor: "#C0B7A4", color: "white" }}
                onClick={handleNewCustotmer}
              >
                New ...
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Paper>
  );
};

export default CustomerSearchForm;
