import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { EtlInvoiceJobFullQuery } from "../../generated/graphql";
import dayjs from "dayjs";
import theme from "../../Theme";

interface InvoiceItemsTableProps {
  etlInvoiceJob: EtlInvoiceJobFullQuery;
}

const ETLInvoiceJobItemsTable: React.FC<InvoiceItemsTableProps> = ({
  etlInvoiceJob,
}) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: theme.palette.primary.main }}>#</TableCell>
            <TableCell sx={{ color: theme.palette.primary.main }}>
              Customer
            </TableCell>
            <TableCell sx={{ color: theme.palette.primary.main }}>
              Date
            </TableCell>
            <TableCell sx={{ color: theme.palette.primary.main }}>
              Record Locator
            </TableCell>
            <TableCell sx={{ color: theme.palette.primary.main }}>
              Total
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {etlInvoiceJob.eTLInvoiceJob?.invoices.map((invoice, index) => (
            <TableRow key={index}>
              <TableCell sx={{ bgcolor: "#EEEEEE" }}>
                {invoice.number}
              </TableCell>
              <TableCell sx={{ bgcolor: "#EEEEEE" }}>
                {invoice.dkNumber}
              </TableCell>
              <TableCell sx={{ bgcolor: "#EEEEEE" }}>
                {dayjs(invoice.createdAt).format("MM/DD/YYYY")}
              </TableCell>
              <TableCell sx={{ bgcolor: "#EEEEEE" }} align="center">
                {invoice.recordLocator}
              </TableCell>
              <TableCell sx={{ bgcolor: "#EEEEEE" }}>
                {Number(invoice.total).toFixed(2)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ETLInvoiceJobItemsTable;
