// ... (other imports)
import { ReactNode } from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";

import { useAuthnAuthz } from "../hooks/Authorization";
interface WithPermissionsProps {
    permissions: string[];
    children: ReactNode;
}

const WithPermissions: React.FC<WithPermissionsProps> = ({ permissions, children }) => {
    // ... (same as before)
    const isAllowed = useAuthnAuthz();
    const [isAuthenticated, isAuthorized] = isAllowed(permissions);

    const location = useLocation();
    if (isAuthenticated) {
        return isAuthorized ? <>{children}</> : <></>
    }
    return <Navigate to="/login" state={{ path: location.pathname }} replace />;
};

export default WithPermissions;