import eliteLogo from "../../elite.png";
import * as React from "react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import {
  inoviceMenuItems,
  settingsMenuItems,
  accountMenuItems,
  customersMenuItems,
} from "../menu/ListMenu";
import SSOUserAvatar from "../UserAvatar";
import { Outlet } from "react-router-dom";
import WithPermissions from "../../permissions/WithPermissions";
import { PERMISSIONS } from "../../hooks/Authorization";

function Copyright(props: any) {
  const v = process.env.npm_package_version;
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.elitetravelmanagement.ca/">
        Elite
      </Link>{" "}
      {new Date().getFullYear()}
      {"."} - Version {v}
    </Typography>
  );
}

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: "#333333", // TODO : Move to theme
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

function DashboardContent() {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography color="inherit" noWrap sx={{ flexGrow: 1 }}>
            <img
              height="57"
              src={eliteLogo}
              alt="description of Elite Travel Management - Customers"
            />
          </Typography>
          <SSOUserAvatar />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>

        <WithPermissions
          permissions={[
            PERMISSIONS.VIEW_TPP_INVOICES,
            PERMISSIONS.VIEW_TPP_OWN_INVOICES,
            PERMISSIONS.VIEW_ELT_INVOICES,
            PERMISSIONS.VIEW_ELT_OWN_INVOICES,
          ]}
        >
          <Divider />
          <List component="nav">{inoviceMenuItems}</List>
        </WithPermissions>

        <WithPermissions permissions={[PERMISSIONS.MANAGE_CUSTOMERS]}>
          <Divider />
          <List component="nav">{customersMenuItems}</List>
        </WithPermissions>

        <WithPermissions permissions={[PERMISSIONS.VIEW_PROFILE]}>
          <Divider />
          <List component="nav">{accountMenuItems}</List>
        </WithPermissions>
        <WithPermissions permissions={[PERMISSIONS.MANAGE_SYSTEM]}>
          <List component="nav">{settingsMenuItems}</List>
        </WithPermissions>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[200]
              : theme.palette.grey[600],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
          <Paper elevation={3}>
            <Outlet />
          </Paper>
          <Copyright sx={{ pt: 4 }} />
        </Container>
      </Box>
    </Box>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
