import { isAllowedGroups } from "../permissions/Permissions";
import { useAuth, AuthContextProps } from "react-oidc-context";
export { PERMISSIONS} from "../permissions/Permissions";

export interface SSOClaimsProps {
    auth: AuthContextProps
}

// Hook that returns a function that can be used to check if the user is authenticated and authorized
// Returns a tuple of [boolean, boolean] where the first boolean is true if the user is authenticated and the second boolean is true if the user is authorized
export const useAuthnAuthz = () => {
    const auth = useAuth();
    return (requiredPermissions: string[]) : [boolean, boolean] => {    

        if (!auth.isAuthenticated) {
            return [false, false]
        }
        const userpermission = auth.user?.profile["groups"] as string[];
        return [true, isAllowedGroups(userpermission, requiredPermissions) ];
    }
}

// Hook that returns a function that can be used to check if the user is authorized
// Returns a boolean that is true if the user is authorized
// If user is not authenticated, returns false
export const useAuthz = () => {
    const auth = useAuth();
    return (requiredPermissions: string[]) : boolean => {    

        if (!auth.isAuthenticated) {
            return false
        }
        if (auth.user === undefined) {
            return false
        }
        const userGroups = auth.user?.profile["groups"] as string[];
        return isAllowedGroups(userGroups, requiredPermissions);
    }
}

export const useAuthnIsCustomer = () => {
    const auth = useAuth();
    return () : string[] | undefined => {    
        if (!auth.isAuthenticated) {
            return undefined;
        }
        // Check if the user is a customer or not:
        const dkNumbersCSV = auth.user?.profile["DKNUMBERS"] as string;
        if (!dkNumbersCSV)
            return undefined;
        const dkNumbers: string[] = dkNumbersCSV.split(",").map(item => item.trim());
        return dkNumbers;

    }
}