import React from 'react';
import { Box } from '@mui/material';
import { ReactNode } from 'react';
import axios from 'axios';
import { format } from 'date-fns';

type ReportType = "Report" | "ReportInvoices";
interface FullSizeComponentProps {
  children?: ReactNode;
}

function humanDateTime(d: Date) {
  if (!d) return '';
  return format(d, 'yyyy-MM-dd HH:mm:ss O');
}

/**
 * Format bytes as human-readable text.
 * 
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use 
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 * 
 * @return Formatted string.
 */
function humanFileSize(bytes: number, si=false, dp=1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si 
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}

function withFullSize<P extends FullSizeComponentProps>(Component: React.ComponentType<P>) {
  return function FullSizeComponent(props: P) {
    return (
      <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
        <Component {...props as P} />
      </Box>
    );
  };
}
const handleInvoicesDownload = async (authToken: string | undefined, numbers: number[]): Promise<void> => {

  try {
      // Step 1: Request a download token from the back-end
      const { data } = await axios.post<{ token: string }>(`${process.env.REACT_APP_INVOICE_API_URL}/api/downloadInvoicesLink`, {
          numbers,
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      const { token } = data;
      if (!token) {
          return;
      }

      // Step 2: Use the token to download the invoice
      const downloadUrl = `${process.env.REACT_APP_INVOICE_API_URL}/api/downloadInvoicesZip/${token}`;
      const { data: fileData } = await axios.get(downloadUrl, {
        headers: {
          Authorization: `Bearer ${authToken}`
        },
        responseType: 'blob' // Important: Indicate that you expect a Blob
      });
  
      const blob = new Blob([fileData], { type: `application/zip` })
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `invoices-${new Date().toISOString()}.zip`;  // Add your desired download file name here
      link.click();
  
      // It's important to revoke the object URL to avoid memory leaks
      window.URL.revokeObjectURL(url);;
  } catch (error) {
      console.error('Error downloading the invoice', error);
  }
};


const handleInvoiceDownload = async (authToken: string | undefined, number: number): Promise<void> => {

  try {
      // Step 1: Request a download token from the back-end
      const { data } = await axios.post<{ token: string }>(`${process.env.REACT_APP_INVOICE_API_URL}/api/downloadInvoiceLink`, {
          number,
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      const { token } = data;
      if (!token) {
          return;
      }

      // Step 2: Use the token to download the invoice
      const downloadUrl = `${process.env.REACT_APP_INVOICE_API_URL}/api/downloadInvoice/${token}`;
      const { data: fileData } = await axios.get(downloadUrl, {
        headers: {
          Authorization: `Bearer ${authToken}`
        },
        responseType: 'blob' // Important: Indicate that you expect a Blob
      });
  
      const blob = new Blob([fileData], { type: `application/pdf` })
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `invoice-${number}.pdf`;  // Add your desired download file name here
      link.click();
  
      // It's important to revoke the object URL to avoid memory leaks
      window.URL.revokeObjectURL(url);;
  } catch (error) {
      console.error('Error downloading the invoice', error);
  }
};

/**
 * 
 * @param type Valid types are: 'Report', 'Invoices'
 * @param jobId 
 * @returns 
 */
const handleReportDownload = async (authToken: string | undefined, type: ReportType, dataId: string, jobId: number): Promise<void> => {
  
  const fileType = type === "Report" ? "pdf" : "zip";

  try {
    // Step 1: Request a download token from the back-end
    const { data } = await axios.post<{ token: string }>(
      `${process.env.REACT_APP_INVOICE_API_URL}/api/downloadReportLink`,
      {
        jobId,
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    const { token } = data;
    if (!token) {
      return;
    }

    // Step 2: Use the token to download the invoice
    const downloadUrl = `${process.env.REACT_APP_INVOICE_API_URL}/api/download${type}/${token}`;
    const { data: fileData } = await axios.get(downloadUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      },
      responseType: 'blob' // Important: Indicate that you expect a Blob
    });

    const blob = new Blob([fileData], { type: `application/${fileType}` })
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${dataId}-${jobId}.${fileType}`;  // Add your desired download file name here
    link.click();

    // It's important to revoke the object URL to avoid memory leaks
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading the report', error);
  } 
}

const handleCSVDownload = async (authToken: string | undefined, dataId: string): Promise<void> => {
  
  const fileType = "csv";

  try {
    // Step 1: Request a download token from the back-end
    const { data } = await axios.post<{ token: string }>(
      `${process.env.REACT_APP_INVOICE_API_URL}/api/downloadCSVLink`,
      {
        dataId,
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    const { token } = data;
    if (!token) {
      return;
    }

    // Step 2: Use the token to download the invoice
    const downloadUrl = `${process.env.REACT_APP_INVOICE_API_URL}/api/downloadCSV/${token}`;
    const { data: fileData } = await axios.get(downloadUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      },
      responseType: 'blob' // Important: Indicate that you expect a Blob
    });

    const blob = new Blob([fileData], { type: `application/${fileType}` })
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${dataId}`;  // Add your desired download file name here
    link.click();

    // It's important to revoke the object URL to avoid memory leaks
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading the report', error);
  } 
}

export default withFullSize;

export { handleReportDownload, handleInvoiceDownload, handleInvoicesDownload, handleCSVDownload, humanFileSize, humanDateTime }
