import * as Apollo from "@apollo/client";
import {
  CustomerCreateOneMutation,
  CustomerCreateOneMutationVariables,
  CustomerUpdateOneMutation,
  CustomerUpdateOneMutationVariables,
} from "../../../generated/graphql";
import Customer, {
  CustomerAddress,
  CustomerUDID,
} from "../../../models/Customer";
import {
  buildCreateAddressesMany,
  buildUpdateAddressesMany,
  buildDeleteAddressesMany,
} from "./CustomerAddress";
import {
  buildCreateUDIDsMany,
  buildUpdateUDIDsMany,
  buildDeleteUDIDsMany,
} from "./CustomerUDID";

const makeCreateCustomerFn = (
  c: Customer,
  execCreateCustomerFn: (
    d: Apollo.MutationHookOptions<
      CustomerCreateOneMutation,
      CustomerCreateOneMutationVariables
    >,
  ) => any,
) => {
  const addrsCreateMany = buildCreateAddressesMany(c.addresses);
  const udidsCreateMany = buildCreateUDIDsMany(c.udids);
  const gqlData = {
    variables: {
      data: {
        city: c.city,
        country: c.country,
        dkNumber: c.dkNumber,
        type: c.type,
        name: c.name,
        paymentTerm: c.paymentTerm,
        postal: c.postal,
        state: c.state,
        street: c.street,
        addresses: {
          createMany: addrsCreateMany,
        },
        udids: {
          createMany: udidsCreateMany,
        },
      },
    },
  };
  return async () => {
    const response = await execCreateCustomerFn(gqlData);
    const newCustomer =
      response.data && isCustomer(response.data.createOneCustomer)
        ? (response.data.createOneCustomer as Customer)
        : undefined;
    return { customer: newCustomer, errors: response.errors };
  };
};

const makeUpdateCustomerFn = (
  c: Customer,
  execUpdateCustomerFn: (
    d: Apollo.MutationHookOptions<
      CustomerUpdateOneMutation,
      CustomerUpdateOneMutationVariables
    >,
  ) => any,
  deletedAddresses: CustomerAddress[],
  deletedUDIDs: CustomerUDID[],
) => {
  const addrsCreateMany = buildCreateAddressesMany(c.addresses);
  const addrsUpdateMany = buildUpdateAddressesMany(c.addresses);
  const addrsDeleteMany = buildDeleteAddressesMany(deletedAddresses);

  const udidsCreateMany = buildCreateUDIDsMany(c.udids);
  const udidsUpdateMany = buildUpdateUDIDsMany(c.udids);
  const udidsDeleteMany = buildDeleteUDIDsMany(deletedUDIDs);

  const gqlData = {
    variables: {
      data: {
        dkNumber: { set: c.dkNumber },
        type: { set: c.type },
        name: { set: c.name },
        paymentTerm: { set: c.paymentTerm },
        street: { set: c.street },
        city: { set: c.city },
        state: { set: c.state },
        country: { set: c.country },
        postal: { set: c.postal },
        addresses: {
          createMany: addrsCreateMany,
          updateMany: addrsUpdateMany,
          deleteMany: addrsDeleteMany,
        },
        udids: {
          createMany: udidsCreateMany,
          updateMany: udidsUpdateMany,
          deleteMany: udidsDeleteMany,
        },
      },
      where: { id: c.id },
    },
  };

  return async () => {
    const response = await execUpdateCustomerFn(gqlData);

    const newCustomer =
      response.data && isCustomer(response.data.updateOneCustomer)
        ? (response.data.updateOneCustomer as Customer)
        : undefined;

    return { customer: newCustomer, errors: response.errors };
  };
};

function isCustomer(obj: any): obj is Customer {
  return obj && obj.id !== undefined && obj.name !== undefined;
}

function toUICodes(c: Customer): Customer {
  // Update addresses, remove dk- from name
  const addresses = c.addresses
    .map((address) => {
      return {
        ...address,
        code: address.code.replace(`${c?.dkNumber}-`, ""),
      };
    })
    .sort((a, b) => {
      if (a.code < b.code) {
        return -1;
      } else if (a.code > b.code) {
        return 1;
      } else {
        return 0;
      }
    });
  // Update udids, remove udid- from udid (code)
  const udids = c.udids
    .map((udid) => {
      return {
        ...udid,
        udid: udid.udid.replace("udid", ""),
      };
    })
    .sort((a, b) => {
      if (a.udid < b.udid) {
        return -1;
      } else if (a.udid > b.udid) {
        return 1;
      } else {
        return 0;
      }
    });
  const r: Customer = { ...c, addresses: addresses, udids: udids };
  return r;
}

function toDBCodes(c: Customer): Customer {
  // Update addresses, remove dk- from name
  const addresses = c.addresses.map((address) => {
    return {
      ...address,
      code: `${c?.dkNumber}-${address.code}`,
    };
  });
  // Update udids, remove udid- from udid (code)
  const udids = c.udids.map((udid) => {
    return {
      ...udid,
      udid: `udid${udid.udid.padStart(2, "0")}`,
    };
  });
  const r = { ...c, addresses: addresses, udids: udids };
  return r;
}

export { makeCreateCustomerFn, makeUpdateCustomerFn, toUICodes, toDBCodes };
