import React, { ChangeEvent, useState } from 'react';
import { Button } from '@mui/material';
import theme from '../../Theme';


interface FileUploadProps {
  apiUrl: string
  type?: "file" | "image"
  accept?: string
  authz?: string
  onStart?: () => void
  onEnd?: (fName: string) => void
  onError?: (error: Error) => void
}

const FileUpload: React.FC<FileUploadProps> = ({ onStart, onEnd, onError, apiUrl, type = "file", accept = ".csv", authz }) => {
  //const [selectedFile, setSelectedFile] = useState<File | null>(null);


  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (onStart) { onStart(); }
      const files = e.target.files;
      
      if (files && files.length > 0) {
        const fName = files[0].name;
        await uploadFile(files[0]);
        if (onEnd) onEnd(fName)
      }
    } catch (error) {
      if (onError) {
        onError(new Error(String(error)));
      }
    }
  };

  const uploadFile = async (selectedFile: File) => {
    if (selectedFile) {
      // Read the content of the file
      const fileContent = await readFileAsText(selectedFile);

      // Create JSON payload
      const payload = {
        fileName: selectedFile.name,
        data: fileContent
      };

      // Upload the JSON payload to the server
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + authz
        },
        body: JSON.stringify(payload)
      });

      if (response.ok) {

        // read response
        const responseJson = await response.json();
        
      } else {
        console.log('File upload failed');
      }
    }
  };

  // Helper function to read file as text
  const readFileAsText = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = () => reject(new Error('File reading failed'));
    });
  };

  return (
    <div>

      <Button
        variant="contained"
        component="label"
        sx={{ bgcolor: "#C0B7A4", color: 'white' }}
      >
        UPLOAD ...
        <input
          type={type}
          hidden
          accept={accept}
          onChange={handleFileChange}
        />
      </Button>
    </div>
  );


};

export default FileUpload;