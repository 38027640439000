import { CustomerAddress } from "../../../models/Customer";

const buildCreateAddressesMany = (addresses: CustomerAddress[]) => {

    const d = addresses
        .filter(address => address.id < 0)
        .map(address => (
            {
                city: address.city,
                code: address.code,
                country: address.country,
                name: address.name,
                postal: address.postal,
                state: address.state,
                street: address.street,
            }
        ));

    if (d.length > 0) {
        return {
            data: d
        };
    }

    return undefined;
};

const buildUpdateAddressesMany = (addresses: CustomerAddress[]) => {

    const d = addresses
        .filter(address => address.id < 0)
        .map(address => (
            {
                data: {
                    city: { set: address.city },
                    code: { set: address.code },
                    country: { set: address.country },
                    name: { set: address.name },
                    postal: { set: address.postal },
                    state: { set: address.state },
                    street: { set: address.street },
                },
                where: {
                    id: { equals: address.id }
                }
            }
        ));

    if (d.length > 0) {
        d
    }

    return undefined;
};

const buildDeleteAddressesMany = (addresses: CustomerAddress[]) => {

    const deleteMany = addresses.filter(address => address.id > 0).map(address => (
        {
            id: { equals: address.id }
        }
    ));

    return deleteMany;
}

export { buildCreateAddressesMany, buildUpdateAddressesMany, buildDeleteAddressesMany }