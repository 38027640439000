import React from 'react';
import { IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface PaginationButtonsProps {
  onPrev: () => void;
  onNext: () => void;
}

interface PaginationButtonProps {
  onChange: () => void;
}

const PaginationButtons: React.FC<PaginationButtonsProps> = ({ onPrev, onNext }) => {
  return (
    <div>
      <IconButton onClick={onPrev}>
        <ArrowBackIosIcon />
      </IconButton>
      <IconButton onClick={onNext}>
        <ArrowForwardIosIcon />
      </IconButton>
    </div>
  );
}

const PaginationButtonPrev: React.FC<PaginationButtonProps> = ({ onChange }) => {
  return (
    <div>
      <IconButton onClick={onChange}>
        <ArrowBackIosIcon />
      </IconButton>
    </div>
  );
}

const PaginationButtonNext: React.FC<PaginationButtonProps> = ({ onChange }) => {
  return (
    <div>
      <IconButton onClick={onChange}>
        <ArrowForwardIosIcon />
      </IconButton>
    </div>
  );
}

export default PaginationButtons;

export { PaginationButtonPrev, PaginationButtonNext };
