import { useState } from "react";
import {
  EtlInvoiceJobWhereInput,
  useEtlInvoiceJobsWhereQuery,
} from "../../generated/graphql";
import {
  Backdrop,
  Divider,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import ETLJobSearchForm from "./ETLInvoiceJobSearchForm";
import ETLJobTable from "./ETLInvoiceJobTable";
import dayjs from "dayjs";

const PAGE_SIZE = 50; // change this to your desired page size

type SearchParams = {
  number: string | null;
  dataset: string | null;
  dateFrom: Date | null;
  dateTo: Date | null;
  status: string | null;
};

const ETLJobs = () => {
  const [searchParams, setSearchParams] = useState<SearchParams>({
    number: null,
    dataset: "",
    dateFrom: dayjs().subtract(30, "day").toDate(), // first day of the current month
    dateTo: dayjs().toDate(), // today
    status: "OK",
  });

  // This state will hold the submitted search params
  const [submittedParams, setSubmittedParams] = useState<SearchParams>({
    number: null,
    dataset: "",
    dateFrom: dayjs().subtract(30, "day").toDate(), // first day of the current month
    dateTo: dayjs().toDate(), // today
    status: "OK",
  });

  const [page, setPage] = useState(1);

  const handleSearch = (params: SearchParams) => {
    setPage(1);
    setSubmittedParams(params);
  };

  const hasParams = (params: SearchParams) => {
    // return true if any of the string params is not null or empty
    if (params.number !== null) return true;
    if (params.dataset !== null && params.dataset !== "") return true;
    if (params.status !== null && params.status !== "ALL") return true;
    // return true if any of the dates is not null
    if (params.dateFrom !== null) return true;
    if (params.dateTo !== null) return true;

    return false;
  };

  const buildWhereClause = (params: SearchParams) => {
    const whereClause: Partial<EtlInvoiceJobWhereInput> = {};

    if (params.number) {
      whereClause.id = { equals: parseInt(params.number) };
    }

    if (params.dataset) {
      whereClause.dataId = { startsWith: params.dataset };
    }

    if (params.dateFrom && params.dateTo) {
      whereClause.AND = [
        { createdAt: { gte: params.dateFrom } },
        { createdAt: { lte: params.dateTo } },
      ];
    } else if (params.dateTo) {
      whereClause.createdAt = { lte: params.dateTo };
    } else if (params.dateFrom) {
      whereClause.createdAt = { gte: params.dateFrom };
    }

    if (params.status && params.status !== "ALL") {
      whereClause.status = { equals: params.status };
    }

    return whereClause;
  };

  const loadNextPage = () => {
    if (page === totalPages) return;

    fetchMore({
      variables: {
        skip: (page + 1) * PAGE_SIZE,
        take: PAGE_SIZE,
      },
    });
    setPage(page + 1);
  };

  const loadPrevPage = () => {
    if (page === 1) return;

    fetchMore({
      variables: {
        skip: (page - 1) * PAGE_SIZE,
        take: PAGE_SIZE,
      },
    });
    setPage(page - 1);
  };

  // Use submittedParams here, not searchParams
  const { data, loading, error, fetchMore } = useEtlInvoiceJobsWhereQuery({
    variables: {
      where: buildWhereClause(submittedParams),
      skip: (page - 1) * PAGE_SIZE,
      take: PAGE_SIZE,
    },

    skip: hasParams(submittedParams) === false,
  });

  // Assuming data?.aggregateInvoice?._count?.number contains the total count of invoices
  const totalCount = data?.aggregateETLInvoiceJob?._count?.id || 0;

  // Calculate total pages
  const totalPages = Math.ceil(totalCount / PAGE_SIZE);

  return (
    <Box sx={{ margin: 2, pt: 3, p: 1, minHeight: "80vh" }}>
      <Typography variant="h3" component="h1" gutterBottom>
        Import: Jobs
      </Typography>
      <Divider sx={{ my: 2, bgcolor: "secondary.main" }} />
      <ETLJobSearchForm
        onSearch={handleSearch}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        loading={loading}
        error={error}
      />

      {!loading && data && (
        <ETLJobTable
          etlinvoicejobData={data}
          handlePrevPage={loadPrevPage}
          handleNextPage={loadNextPage}
          currentPage={page}
          totalPages={totalPages}
        />
      )}

      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <CircularProgress />
      </Backdrop>
    </Box>
  );
};

export default ETLJobs;
export type { SearchParams };
