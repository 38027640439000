import { IdTokenClaims } from "oidc-client-ts";

export const PERMISSIONS = {
  VIEW_ABOUT: "view_about",
  VIEW_TPP_INVOICES: "view_ttp_invoices",
  VIEW_ELT_INVOICES: "view_elt_invoices",
  VIEW_TPP_OWN_INVOICES: "view_tpp_own_invoices",
  VIEW_ELT_OWN_INVOICES: "view_elt_own_invoices",
  VIEW_OWN_USERS: "view_own_users",
  MANAGE_OWN_USERS: "manage_own_users",
  VIEW_PROFILE: "view_profile",
  VIEW_SETTINGS: "view_settings",
  VIEW_JOBS: "view_jobs",
  VIEW_USERS: "view_users",
  MANAGE_USERS: "manage_users",
  VIEW_CUSTOMERS: "view_customers",
  MANAGE_CUSTOMERS: "manage_customers",
  MANAGE_SYSTEM: "manage_system",
};

export default PERMISSIONS;

// TODO : Configure in DB
export const GROUPS = {
  ROLE_CUSTOMER_USER: [PERMISSIONS.VIEW_ABOUT, PERMISSIONS.VIEW_PROFILE],
  ROLE_CUSTOMER_TPP: [PERMISSIONS.VIEW_TPP_OWN_INVOICES],
  ROLE_CUSTOMER_ELT: [PERMISSIONS.VIEW_ELT_OWN_INVOICES],
  ROLE_CUSTOMER_ADMIN: [
    PERMISSIONS.MANAGE_OWN_USERS,
    PERMISSIONS.VIEW_OWN_USERS,
  ],
  ROLE_ELITE_USER: [PERMISSIONS.VIEW_ABOUT, PERMISSIONS.VIEW_PROFILE],
  ROLE_ELITE_STAFF: [
    PERMISSIONS.VIEW_TPP_INVOICES,
    PERMISSIONS.VIEW_ELT_INVOICES,
  ],
  ROLE_ELITE_ADMIN: [
    PERMISSIONS.VIEW_ABOUT,
    PERMISSIONS.VIEW_TPP_INVOICES,
    PERMISSIONS.VIEW_ELT_INVOICES,
    PERMISSIONS.VIEW_PROFILE,
    PERMISSIONS.VIEW_SETTINGS,
    PERMISSIONS.VIEW_JOBS,
    PERMISSIONS.VIEW_CUSTOMERS,
    PERMISSIONS.MANAGE_CUSTOMERS,
    PERMISSIONS.VIEW_USERS,
    PERMISSIONS.MANAGE_USERS,
    PERMISSIONS.VIEW_ELT_INVOICES,
    PERMISSIONS.VIEW_TPP_INVOICES,
  ],

  // TODO : Make dynamic, this is actually : All Users!
  "d6b2a937-9478-4460-b3a7-273ba634ee26": [
    PERMISSIONS.VIEW_ABOUT,
    PERMISSIONS.VIEW_PROFILE,
    PERMISSIONS.VIEW_SETTINGS,
    PERMISSIONS.VIEW_JOBS,
    PERMISSIONS.VIEW_CUSTOMERS,
    PERMISSIONS.MANAGE_CUSTOMERS,
    PERMISSIONS.VIEW_USERS,
    PERMISSIONS.MANAGE_USERS,
    PERMISSIONS.VIEW_TPP_INVOICES,
    PERMISSIONS.VIEW_ELT_INVOICES,
  ],
};

export function GetPermissionsForGroup(group: string) {
  for (const [key, value] of Object.entries(GROUPS)) {
    if (key === group) {
      return value;
    }
  }
  // Default group
  return GROUPS.ROLE_ELITE_USER;
}

export function IsCustomer() {
  return true;
}

export function GetPermissionsForGroups(groups: string[]) {
  let permissions: string[] = [];
  for (const group of groups) {
    permissions = permissions.concat(GetPermissionsForGroup(group));
  }
  return permissions;
}

export function isAllowedGroups(groups: string[], permissions: string[]) {
  if (groups === undefined) {
    return false;
  }
  const userPermissions = GetPermissionsForGroups(groups);
  return permissions.some((allowed) => userPermissions.includes(allowed));
}

export function isAllowedToken(
  idToken: IdTokenClaims | undefined,
  permissions: string[],
) {
  if (idToken === undefined) {
    return false;
  }
  const g = idToken.groups as string[];
  const userPermissions = GetPermissionsForGroups(g);
  return permissions.some((allowed) => userPermissions.includes(allowed));
}
