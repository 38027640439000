import * as React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import myImage from '../../elite.png';

const Loading: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      }}
    >
      <Box 
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end', 
          marginBottom: '3em', // Add space between the Box and the CircularProgress
        }}
      >
        <img 
          src={myImage} 
          alt="Elite Travel Management - Customers" 
          style={{marginBottom: '-1.5em', width: '300px'}} // Reduce space between the image and the text
        />
        <Typography variant="h5" component="div" >Invoicing System</Typography>
      </Box>
      <CircularProgress color="primary" />
    </Box>
  );
};

export default Loading;
